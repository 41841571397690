'use strict';

import globalStyles from "GlobalStyles";

window.Components = function (Components) {
    function AudioserverComponent(serverUuid) {
        this.serverUuid = serverUuid;
        this.name = "AudioServerComp@" + this.getServerName();
        this.extensions = {};
        this.informedAboutApiMissmatch = null;
        this.apiMissmatch = false;
        this.audioViewsVisible = false;
        this.taskRecorderActive = false;
        this.activeZoneControl = null;
        this.audioZoneExtListenerObj = {};
        this.connectionReadyDef = Q.defer();
        this.Feature = new AudioFeatureCheck();
        this.Feature.name = this.Feature.name + this.name;
        this.Feature.update(MusicServerEnum.RESET_API_VERSION); // initializes this component as extension-channel

        Observer.call(this); // Register for Component-Channel Events

        this.observers = {
            comp: {
                channel: CompChannel,
                registrations: []
            },
            ext: {
                channel: this,
                registrations: []
            }
        };
        this.observers.comp.registrations.push(CompChannel.on(CCEvent.ConnClosed, this._handleStopMSSession.bind(this)));
        this.observers.comp.registrations.push(CompChannel.on(CCEvent.Pause, this._handleStopMSSession.bind(this)));
        this.observers.comp.registrations.push(CompChannel.on(CCEvent.StructureReady, this._handleStructureReady.bind(this)));
        this.observers.comp.registrations.push(CompChannel.on(CCEvent.StopMSSession, this._handleStopMSSession.bind(this)));
        this.observers.comp.registrations.push(CompChannel.on(CCEvent.TaskRecorderStart, this._handleTaskRecorderStart.bind(this)));
        this.observers.comp.registrations.push(CompChannel.on(CCEvent.TaskRecorderEnd, this._handleTaskRecorderEnd.bind(this))); // Also register for extension-channel events

        this.observers.ext.registrations.push(this.on(this.ECEvent.ResultReceived, this._handleResult.bind(this)));
        this.observers.ext.registrations.push(this.on(this.ECEvent.ResultErrorReceived, this._handleErrorResult.bind(this)));
        this.observers.ext.registrations.push(this.on(this.ECEvent.ServerInfoReceived, this._handleServerInfoReceived.bind(this)));
        this.observers.ext.registrations.push(this.on(this.ECEvent.FeatureCheckingReady, this._handleFeatureCheckingReady.bind(this)));
        this.observers.ext.registrations.push(this.on(this.ECEvent.ConnEstablished, this._handleConnEstablished.bind(this)));
        this.observers.ext.registrations.push(this.on(this.ECEvent.AuthenticationChallenge, this._authenticationChallenge.bind(this)));
        this.observers.ext.registrations.push(this.on(this.ECEvent.ConnClosed, this._handleConnClosed.bind(this)));
    }

    /**
     * Takes an item as input and creates an object containing both a title and subtitle to be displayed
     * for this item.
     * @param item
     * @returns {{title: string, subtitle: string}}
     */


    AudioserverComponent.prototype.getTitleSubtitleForItem = function getTitleSubtitleForItem(item) {
        var mainText = "";
        var subText = "";

        if (item.hasOwnProperty(MusicServerEnum.Event.NAME) && item[MusicServerEnum.Event.NAME]) {
            mainText = item[MusicServerEnum.Event.NAME];

            if (item.hasOwnProperty(MusicServerEnum.Event.ARTIST) && item[MusicServerEnum.Event.ARTIST] !== "") {
                subText = item[MusicServerEnum.Event.ARTIST];
            }
        } else {
            if (item.hasOwnProperty(MusicServerEnum.Event.TITLE)) {
                mainText = item[MusicServerEnum.Event.TITLE];

                if (item[MusicServerEnum.Event.ARTIST] && item[MusicServerEnum.Event.ARTIST] !== "") {
                    subText = item[MusicServerEnum.Event.ARTIST];
                }

                if (item[MusicServerEnum.Event.ALBUM] && item[MusicServerEnum.Event.ALBUM] !== "") {
                    if (subText) {
                        subText += SEPARATOR_SYMBOL + item[MusicServerEnum.Event.ALBUM];
                    } else {
                        subText = item[MusicServerEnum.Event.ALBUM];
                    }
                }

                if (mainText === "") {
                    // if the title was empty, swap main and subtext
                    mainText = subText;
                    subText = "";
                }
            } // maybe we have a radioStation set?


            if (item.hasOwnProperty(MusicServerEnum.Event.STATION) && item[MusicServerEnum.Event.STATION] !== "") {
                if (subText !== "") {
                    mainText += SEPARATOR_SYMBOL + subText;
                }

                subText = mainText;
                mainText = item[MusicServerEnum.Event.STATION];
            }

            if (mainText === "" && subText === "") {
                mainText = _("media.no-music-selected");
                subText = "";
            }
        } //  playlists e.g. have an owner attribute, that's especially important for spotify, where playlists can be shared.


        if (item.hasOwnProperty(MusicServerEnum.Event.OWNER) && item[MusicServerEnum.Event.OWNER] !== "") {
            subText = subText === "" ? item[MusicServerEnum.Event.OWNER] : subText + SEPARATOR_SYMBOL + item[MusicServerEnum.Event.OWNER];
        }

        return {
            title: mainText,
            subtitle: subText
        };
    };

    AudioserverComponent.prototype.defaultIconForAudioType = function defaultIconForAudioType(audioType) {
        var iconSrc;

        switch (audioType) {
            case MusicServerEnum.AudioType.PLAYLIST:
                iconSrc = Icon.AudioZone.PLAYLIST_COVER;
                break;

            case MusicServerEnum.AudioType.STREAM:
                iconSrc = Icon.AudioZone.Cover.STREAM;
                break;

            case MusicServerEnum.AudioType.FILE:
                iconSrc = Icon.AudioZone.TITLE_COVER;
                break;

            case MusicServerEnum.AudioType.BLUETOOTH:
                iconSrc = Icon.AudioZone.Cover.BLUETOOTH;
                break;

            case MusicServerEnum.AudioType.LINEIN:
                iconSrc = Icon.AudioZone.Cover.LINE_IN;
                break;

            case MusicServerEnum.AudioType.AIRPLAY:
                iconSrc = Icon.AudioZone.Cover.AIRPLAY;
                break;

            default:
                iconSrc = this.getDefaultIconForUnknown();
                break;
        }

        return iconSrc;
    };

    AudioserverComponent.prototype.getDefaultIconForUnknown = function getDefaultIconForUnknown() {
        return "resources/Images/General/Popups/caution.svg";
    };

    AudioserverComponent.prototype.defaultIconForType = function defaultIconForType(type) {
        var iconSrc;

        switch (type) {
            case MusicServerEnum.FileType.FAVORITE:
                iconSrc = "resources/Images/Controls/AudioZone/favorits.svg";
                break;

            case MusicServerEnum.FileType.ROOM_FAVORITE:
                iconSrc = Icon.AudioZone.ZONE_FAV_COVER;
                break;

            case MusicServerEnum.FileType.HW_INPUT:
                iconSrc = "resources/Images/Controls/AudioZone/icon-line-in.svg";
                break;

            case MusicServerEnum.FileType.LOCAL_PLAYLIST:
            case MusicServerEnum.FileType.PLAYLIST_BROWSABLE:
            case MusicServerEnum.FileType.PLAYLIST_EDITABLE:
            case MusicServerEnum.FileType.FOLLOWED_PLAYLIST:
                iconSrc = Icon.AudioZone.PLAYLIST_COVER;
                break;

            case MusicServerEnum.FileType.LOCAL_DIR:
                iconSrc = "resources/Images/Controls/AudioZone/cover-folder.svg";
                break;

            case MusicServerEnum.FileType.LOCAL_FILE:
                iconSrc = Icon.AudioZone.TITLE_COVER;
                break;

            default:
                iconSrc = this.getDefaultIconForUnknown();
                break;
        }

        return iconSrc;
    };
    /**
     * Returns info on whether or not a certain content type is editable or not
     * @param contentType   the mediaContentType to check (playlist, favorites, ..)
     * @param [fileType]    e.g. when it comes to playlists, not all of them are editable.
     * @param [mediaTypeDetails]    e.g. when it comes to playlists, not all of them are editable. google music e.g. is not editable.
     * @returns {boolean}
     */


    AudioserverComponent.prototype.isEditableContentType = function isEditableContentType(contentType, fileType, mediaTypeDetails) {
        var result = false;

        switch (contentType) {
            case MusicServerEnum.MediaContentType.FAVORITES:
            case MusicServerEnum.MediaContentType.ZONE_FAVORITES:
                result = true;
                break;

            case MusicServerEnum.MediaContentType.PLAYLISTS:
                result = fileType === MusicServerEnum.FileType.PLAYLIST_EDITABLE;

                if (result && mediaTypeDetails && mediaTypeDetails.service) {
                    result = mediaTypeDetails.service[MusicServerEnum.Attr.SERVICE.CMD] !== MusicServerEnum.Service.GOOGLE;
                }

                break;

            default:
                break;
        }

        return result;
    };
    /**
     * Detects whether or not a list should be played from start to end or shuffled.
     * @param item
     * @returns {boolean}
     */


    AudioserverComponent.prototype.useShufflePlay = function useShufflePlay(item) {
        var showShuffle = false;

        if (item.contentType === MusicServerEnum.MediaContentType.ZONE_FAVORITES) {
            switch (item.type) {
                case MusicServerEnum.FAVORITE_TYPES.LMS_PLAYLIST:
                case MusicServerEnum.FAVORITE_TYPES.LIB_FOLDER:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_PLAYLIST:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_COLLECTION:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_SHOW:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ARTIST:
                    showShuffle = true;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ALBUM:
                    showShuffle = true; // TODO-woessto: AUDIO1-I2402 - noShuffle doesn't work on favorites yet.

                    break;

                default:
                    break;
            }
        } else if (item.contentType === MusicServerEnum.MediaContentType.SERVICE) {
            switch (item.type) {
                case MusicServerEnum.FileType.PLAYLIST_BROWSABLE:
                case MusicServerEnum.FileType.PLAYLIST_EDITABLE:
                case MusicServerEnum.FileType.FOLLOWED_PLAYLIST:
                    showShuffle = item.tag !== "album";
                    break;

                default:
                    showShuffle = this.isFileTypeBrowsable(item.type);
                    break;
            }
        } else {
            showShuffle = this.isFileTypeBrowsable(item.type);
        }

        return showShuffle;
    };

    AudioserverComponent.prototype.isFileTypeBrowsable = function isFileTypeBrowsable(fileType) {
        var result = false;

        switch (fileType) {
            case MusicServerEnum.FileType.LOCAL_DIR:
                result = true;
                break;

            default:
                result = this.isFileTypePlaylist(fileType);
                break;
        }

        return result;
    };

    AudioserverComponent.prototype.canFollowServiceItem = function canFollowServiceItem(item) {
        return !this.isSpotifyEpisodeOrShow(item); // spotify shows or episodes cannot be followed!
    };

    AudioserverComponent.prototype.isSpotifyUrl = function isSpotifyUrl(spotifyUrl) {
        // e.g https://open.spotify.com/playlist/3mRX2cgkw2mabVvHMbpCB0?si=bc3878a1b3554295
        return spotifyUrl.indexOf("open.spotify.com") >= 0;
    };

    AudioserverComponent.prototype.isSpotifyItemWithTag = function isSpotifyItemWithTag(item, wantedTags) {
        var path = item[MusicServerEnum.Event.AUDIO_PATH],
            itemTag = item[MusicServerEnum.Attr.Item.TAG],
            wantedTagsList = wantedTags && Array.isArray(wantedTags) ? wantedTags : [wantedTags],
            parts;

        if (!itemTag && path && path.startsWith(MusicServerEnum.Service.SPOTIFY)) {
            // split up audio path, when it comes to favorites, the tag is only part of the path: e.g.:
            // spotify@9k1uyyfgstvmzy9u2jdnzvh7s:show:1OLcQdw2PFDPG1jo3s0wbp
            parts = path.split(":");

            if (parts.length >= 3) {
                itemTag = parts[1];
            }
        }

        return wantedTagsList.some(checkTag => {
            return checkTag === itemTag;
        });
    };

    AudioserverComponent.prototype.isSpotifyEpisodeOrShow = function isSpotifyEpisodeOrShow(item) {
        return this.isSpotifyItemWithTag(item, [MusicServerEnum.Tag.EPISODE, MusicServerEnum.Tag.SHOW]);
    };

    AudioserverComponent.prototype.isSpotifyTrackOrEpisode = function isSpotifyTrackOrEpisode(item) {
        return this.isSpotifyItemWithTag(item, [MusicServerEnum.Tag.TRACK, MusicServerEnum.Tag.EPISODE]);
    };

    AudioserverComponent.prototype._createItemFromSpotifyUrl = function _createItemFromSpotifyUrl(spotifyUrl, serviceAccount) {
        Debug.Media.SpotifyLinks && console.log(this.name, "_createItemFromSpotifyUrl: " + spotifyUrl);
        var adoptedUrlString = spotifyUrl,
            url,
            user = serviceAccount.id,
            spotifyPath,
            pathParts,
            typeId,
            itemId,
            itemAudioPath,
            fixedSpotifyItem;

        if (!spotifyUrl.hasPrefix("https://") && !spotifyUrl.hasPrefix("http://")) {
            adoptedUrlString = "https://" + spotifyUrl;
        }

        url = new URL(adoptedUrlString);
        spotifyPath = url.pathname; // "/playlist/3mRX2cgkw2mabVvHMbpCB0"

        pathParts = spotifyPath.split("/"); // [ ""; "playlist"; "3mRX2cgkw2mabVvHMbpCB0" ]

        if (pathParts.length > 2) {
            typeId = pathParts[1]; // "playlist"

            itemId = pathParts[2]; // "3mRX2cgkw2mabVvHMbpCB0"

            /*{
                "audiopath": "spotify@dertoubey:playlist:0M7jGj2WBnxAN4anMxvoqI",
                "coverurl": "https://i.scdn.co/image/ab67706c0000bebbb8a0f91cd2389c2ca3a4c023",
                "id": "spotify@dertoubey:playlist:0M7jGj2WBnxAN4anMxvoqI",
                "name": "https%2F%2F%3Aur%20mum.cum",
                "owner": "Error",
                "ownerId": "tu9wb8r0guiewe8q7cej414jk",
                "tag": "playlist",
                "thumbnail": "https://i.scdn.co/image/ab67706c0000bebbb8a0f91cd2389c2ca3a4c023",
                "type": 7
            }*/

            itemAudioPath = "spotify@" + user + ":" + typeId + ":" + itemId;
            fixedSpotifyItem = {
                audiopath: itemAudioPath,
                id: itemAudioPath,
                tag: typeId,
                type: typeId === MediaEnum.Tag.TRACK ? MediaEnum.FileType.LOCAL_FILE : MediaEnum.FileType.PLAYLIST_BROWSABLE,
                service: {
                    uid: "spotify/" + user
                },
                isSpotify: true,
                _itemId: itemId,
                // stored for internal usage
                _typeId: typeId // stored for internal usage

            };
            Controls.AudioZoneV2Control.MediaBrowserV2Base.applyContentTypeToItem(fixedSpotifyItem);
        }

        return fixedSpotifyItem;
    };
    /**
     * From a spotify url provided, create a mediaItem and navigate to that item.
     * @param spotifyUrl e.g. https://open.spotify.com/playlist/3mRX2cgkw2mabVvHMbpCB0?si=68f8b826a5494e51
     * @param viewCtrl the viewController to use for navigation.
     * @param serviceAccount the currently logged in user
     * @returns {Q.Promise<unknown>|Q.Promise<*>}
     */


    AudioserverComponent.prototype.navigateToSpotifyUrl = function navigateToSpotifyUrl(spotifyUrl, viewCtrl, serviceAccount) {
        Debug.Media.SpotifyLinks && console.log(this.name, "navigateToSpotifyUrl: " + spotifyUrl);

        var spotifyItem = this._createItemFromSpotifyUrl(spotifyUrl, serviceAccount);

        if (spotifyItem) {
            Debug.Media.SpotifyLinks && console.log(this.name, "   request content for " + JSON.stringify(spotifyItem)); // request a content batch preflight in order to immediately provide infos such as cover+name+owner..

            var res = this.requestContentBatch(MediaEnum.MediaContentType.SERVICE, spotifyItem.audiopath, 50, spotifyItem, false, true);
            return res.promise.then(function (data) {
                Debug.Media.SpotifyLinks && console.log(this.name, "   received information from AS: " + JSON.stringify(data));
                updateObjectProperties(data, spotifyItem, ["id", "owner", "owner_id", "artist", "tag", "album", "name", "thumbnail", "coverurl", "followed", "type"]);
                Debug.Media.SpotifyLinks && console.log(this.name, "    navigate to spotify item: " + JSON.stringify(spotifyItem));
                return this.AudioViewController.navigateToItem(spotifyItem, viewCtrl);
            }.bind(this));
        }

        return Q.reject();
    };

    AudioserverComponent.prototype.getAudioserverHostHttp = function getAudioserverHostHttp() {
        return this.extensions.centralCommunicationNode && this.extensions.centralCommunicationNode.getAudioserverHostHttp();
    };
    /**
     * Normalizing means that a favorite-item is being converted into a normal item that can be browsed & so on.
     * This is important for playlists (spotify or regular) that are shown e.g. in the playlistsOverview or in the History.
     * @param item
     * @param details
     * @returns {*}
     */


    AudioserverComponent.prototype.normalizeFavoriteItem = function normalizeFavoriteItem(item, details) {
        var normalizedItem = cloneObjectDeep(item),
            res = this._getFavoriteItemInfos(item.type, item.ownerId);

        normalizedItem[MusicServerEnum.Event.FILE_TYPE] = res.fileType;

        if (res.contentType) {
            normalizedItem.contentType = res.contentType;
        }

        normalizedItem.details = {};
        normalizedItem.details.username = details && details.spotifyUser ? details.spotifyUser.id : normalizedItem.ownerId;
        normalizedItem.details.identifier = res.identifier;
        normalizedItem.details.mediaType = res.mediaType;

        if (res.identifier === MusicServerEnum.ControlContentIdentifiers.SPOTIFY) {
            normalizedItem.details.service = {
                uid: MusicServerEnum.ControlContentIdentifiers.SPOTIFY + "/" + normalizedItem.details.username,
                cmd: MusicServerEnum.ControlContentIdentifiers.SPOTIFY
            };
        }

        normalizedItem.id = normalizedItem.audiopath;
        return normalizedItem;
    };
    /**
     * Helper FN for normalizing favorite items.
     * @param favType
     * @param ownerId
     * @returns {{identifier: string, mediaType: string, contentType: string, fileType: *}}
     * @private
     */


    AudioserverComponent.prototype._getFavoriteItemInfos = function _getFavoriteItemInfos(favType, ownerId) {
        var fileType = MusicServerEnum.FileType.UNDEFINED,
            contentType,
            identifier,
            mediaType;

        switch (favType) {
            case MusicServerEnum.FAVORITE_TYPES.TUNEIN:
            case MusicServerEnum.FAVORITE_TYPES.CUSTOM_STREAM:
                contentType = MusicServerEnum.MediaContentType.SERVICE;
                identifier = MusicServerEnum.ControlContentIdentifiers.TUNE_IN;
                fileType = MusicServerEnum.FAVORITE_TYPES.TUNEIN; // leave on favType tunein.

                mediaType = MusicServerEnum.MediaType.SERVICE;
                break;

            case MusicServerEnum.FAVORITE_TYPES.LMS_PLAYLIST:
                contentType = MusicServerEnum.MediaContentType.PLAYLISTS;
                identifier = MusicServerEnum.ControlContentIdentifiers.LOXONE_PLAYLISTS;
                fileType = MusicServerEnum.FileType.PLAYLIST_EDITABLE;
                mediaType = MusicServerEnum.MediaType.PLAYLIST;
                break;

            case MusicServerEnum.FAVORITE_TYPES.LMS_LINEIN:
                contentType = MusicServerEnum.MediaContentType.LINEIN;
                identifier = MusicServerEnum.ControlContentIdentifiers.LOXONE_LINE_IN;
                fileType = MusicServerEnum.FileType.HW_INPUT;
                break;

            case MusicServerEnum.FAVORITE_TYPES.LIB_FOLDER:
                contentType = MusicServerEnum.MediaContentType.LIBRARY;
                identifier = MusicServerEnum.ControlContentIdentifiers.LOXONE_LIB;
                fileType = MusicServerEnum.FileType.LOCAL_DIR;
                mediaType = MusicServerEnum.MediaType.LIBRARY;
                break;

            case MusicServerEnum.FAVORITE_TYPES.LIB_TRACK:
                contentType = MusicServerEnum.MediaContentType.LIBRARY;
                identifier = MusicServerEnum.ControlContentIdentifiers.LOXONE_LIB;
                fileType = MusicServerEnum.FileType.LOCAL_FILE;
                mediaType = MusicServerEnum.MediaType.LIBRARY;
                break;

            case MusicServerEnum.FAVORITE_TYPES.LIB_PLAYLIST:
                contentType = MusicServerEnum.MediaContentType.LIBRARY;
                identifier = MusicServerEnum.ControlContentIdentifiers.LOXONE_LIB;
                fileType = MusicServerEnum.FileType.LOCAL_PLAYLIST;
                mediaType = MusicServerEnum.MediaType.LIBRARY;
                break;

            case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_PLAYLIST:
                contentType = MusicServerEnum.MediaContentType.SERVICE;
                identifier = MusicServerEnum.ControlContentIdentifiers.SPOTIFY;
                mediaType = MusicServerEnum.MediaType.SERVICE;

                if (ownerId) {
                    // added to AS on 2021.11.09
                    // cannot verify the ownership here, spotify user may not be known yet. Assume editable and verify
                    // when the context menu is opened via getActionsForCtxAndItem in mediaBrowser_Service-11.
                    fileType = MusicServerEnum.FileType.PLAYLIST_EDITABLE;
                } else {
                    fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                }

                break;

            case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_COLLECTION:
                contentType = MusicServerEnum.MediaContentType.SERVICE;
                identifier = MusicServerEnum.ControlContentIdentifiers.SPOTIFY;
                mediaType = MusicServerEnum.MediaType.SERVICE;
                fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                break;

            case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ALBUM:
            case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_SHOW:
                contentType = MusicServerEnum.MediaContentType.SERVICE;
                identifier = MusicServerEnum.ControlContentIdentifiers.SPOTIFY;
                mediaType = MusicServerEnum.MediaType.SERVICE;
                fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                break;

            case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ARTIST:
                contentType = MusicServerEnum.MediaContentType.SERVICE;
                identifier = MusicServerEnum.ControlContentIdentifiers.SPOTIFY;
                mediaType = MusicServerEnum.MediaType.SERVICE;
                fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                break;

            case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_TRACK:
            case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_EPISODE:
                contentType = MusicServerEnum.MediaContentType.SERVICE;
                identifier = MusicServerEnum.ControlContentIdentifiers.SPOTIFY;
                mediaType = MusicServerEnum.MediaType.SERVICE;
                fileType = MusicServerEnum.FileType.FAVORITE;
                break;

            default:
                identifier = MusicServerEnum.ControlContentIdentifiers.LOXONE_FAVS;
                fileType = MusicServerEnum.FileType.UNDEFINED;
                break;
        }

        return {
            fileType: fileType,
            identifier: identifier,
            contentType: contentType,
            mediaType: mediaType
        };
    };

    AudioserverComponent.prototype.isFileTypePlaylist = function isFileTypePlaylist(fileType) {
        var result = false;

        switch (fileType) {
            case MusicServerEnum.FileType.PLAYLIST_BROWSABLE:
            case MusicServerEnum.FileType.PLAYLIST_EDITABLE:
            case MusicServerEnum.FileType.FOLLOWED_PLAYLIST:
                result = true;
                break;

            default:
                result = false;
                break;
        }

        return result;
    };

    AudioserverComponent.prototype.isFileTypePlayable = function isFileTypePlayable(fileType, contentType) {
        var result = false;

        switch (fileType) {
            case MusicServerEnum.FileType.LOCAL_PLAYLIST:
            case MusicServerEnum.FileType.PLAYLIST_BROWSABLE:
            case MusicServerEnum.FileType.LOCAL_FILE:
            case MusicServerEnum.FileType.PLAYLIST_EDITABLE:
            case MusicServerEnum.FileType.FOLLOWED_PLAYLIST:
                result = true;
                break;

            case MusicServerEnum.FileType.LOCAL_DIR:
                result = contentType === MusicServerEnum.MediaContentType.LIBRARY;
                break;

            default:
                // some contentTypes are playable regardless of their fileType.
                result = contentType === MusicServerEnum.MediaContentType.FAVORITES;
                result = result || contentType === MusicServerEnum.MediaContentType.ZONE_FAVORITES; // if something is playing right now, it has to be playable

                result = result || contentType === MusicServerEnum.MediaContentType.PLAYING;
                break;
        }

        return result;
    };

    AudioserverComponent.prototype.isFileTypeEditable = function isFileTypeEditable(fileType) {
        var result;

        switch (fileType) {
            case MusicServerEnum.FileType.PLAYLIST_EDITABLE:
                result = true;
                break;

            default:
                result = false;
                break;
        }

        return result;
    };

    AudioserverComponent.prototype.isFileTypeDeletable = function isFileTypeDeletable(fileType, contentType) {
        var result;

        switch (fileType) {
            case MusicServerEnum.FileType.PLAYLIST_EDITABLE:
                result = true;
                break;

            default:
                result = false;
                break;
        }

        return result;
    };

    AudioserverComponent.prototype.isHandledFileType = function isHandledFileType(fileType) {
        var result = false;

        switch (fileType) {
            case MusicServerEnum.FileType.LOCAL_PLAYLIST:
            case MusicServerEnum.FileType.LOCAL_FILE:
            case MusicServerEnum.FileType.LOCAL_DIR:
            case MusicServerEnum.FileType.HW_INPUT:
            case MusicServerEnum.FileType.FAVORITE:
            case MusicServerEnum.FileType.ROOM_FAVORITE:
            case MusicServerEnum.FileType.PLAYLIST_BROWSABLE:
            case MusicServerEnum.FileType.PLAYLIST_EDITABLE:
            case MusicServerEnum.FileType.FOLLOWED_PLAYLIST:
                result = true;
                break;

            case MusicServerEnum.FileType.SEARCH:
            case MusicServerEnum.FileType.FUNCTION:
            case MusicServerEnum.FileType.TEXT:
                result = false;
                break;

            default:
                result = false;
                break;
        }

        return result;
    }; // Adds the so far defined prototypes to the AudioserverComponent constructor, this way we can use them as static properties
    // Note: Add any prototypes above this line to define static properties (variables, functions, ...)


    Object.assign(AudioserverComponent, AudioserverComponent.prototype);

    AudioserverComponent.prototype.destroy = function destroy() {
        this._destroySingletones(this.activeZoneControl); // clear all event listeners!


        if (this.observers) {
            Object.keys(this.observers).forEach(function (observerKey) {
                this.observers[observerKey].registrations.forEach(function (registration) {
                    this.observers[observerKey].channel.off(registration);
                }.bind(this));
            }.bind(this));
        }

        this._destroyExtensions();

        if (this._hardwareVolumeButtonHandler) {
            this._hardwareVolumeButtonHandler.stop();

            this._hardwareVolumeButtonHandler.destroy();

            delete this._hardwareVolumeButtonHandler;
        }
    };

    AudioserverComponent.prototype.connectionPromise = function connectionPromise() {
        return this.connectionReadyDef ? this.connectionReadyDef.promise : null;
    };

    AudioserverComponent.prototype.isConnectionPromisePending = function isConnectionPromisePending() {
        var pending = false;

        if (this.connectionReadyDef) {
            pending = this.connectionReadyDef.promise.isPending();
        }

        return pending;
    };
    /**
     * Used to START the AudioserverComponent. it'll check if there is a MediaServer ready, instantiate the extensions
     * and then post a start-event on the ext-channel.
     * @private
     */


    AudioserverComponent.prototype._handleStructureReady = function _handleStructureReady() {
        Debug.Media.Component && console.log("AudioserverComp", "CCEvent.StructureReady");
        this.emit(this.ECEvent.VerifyConnectivity); // TODO-woessto: remove workaround https://www.wrike.com/open.htm?id=91947923

        if (this.getActiveMediaServer()) {
            //this._checkExtensions(); // TODO-bialopa: this call is unnecessary as extensions are already there

            if(Debug.Communication) {
                this._socketPrepared = timingNow();
            }
            this.emit(this.ECEvent.Start, { socketPreparedTiming: this._socketPrepared });
        } else {
            this._destroyExtensions();
        }
    };
    /**
     * This is called when the comp-channel receives a stopMSSession event.
     * Used to tear down the whole Media Server-Component. it'll reset the feature checking, reset all caches via the
     * resetMediaApp-Event and post a stop onto the ext-channel.
     * @private
     */


    AudioserverComponent.prototype._handleStopMSSession = function _handleStopMSSession() {
        Debug.Media.Component && console.log("AudioserverComp", "CCEvent.StopMSSession");
        this.audioViewsVisible = false; //this._destroyExtensions();

        if (this.getActiveMediaServer()) {
            // the connection needs to be stopped as soon as any form of conn is active.
            this.emit(this.ECEvent.ResetMediaApp, {
                stopped: true,
                from: "msStop"
            }); // invalidate all caches, but don't reload!

            this.emit(this.ECEvent.Stop);
        }
    };
    /**
     * Update the attribute & emit the corresponding ext-channel event.
     * @private
     */


    AudioserverComponent.prototype._handleTaskRecorderEnd = function _handleTaskRecorderEnd() {
        this.taskRecorderActive = false;
        this.emit(this.ECEvent.TaskRecorderEnd);
    };
    /**
     * Called when the server info was received either by the music-server-proxy or the media-socket.
     * It'll update the Feature-Checking and post the feature-checking-ready event on the ext-channel.
     * @private
     */


    AudioserverComponent.prototype._handleServerInfoReceived = function _handleServerInfoReceived(evId, result) {
        this.serverInfo = result;
        this.Feature.update(this.serverInfo);
        this._availableZones = cloneObject(this.getAvailableZones()); // Notify the Extensions about this

        this.emit(this.ECEvent.FeatureCheckingReady); // calls handleFeatureCheckingReady
    };
    /**
     * Respond to the feature-checking-ready event on the ext-channel. Some extensions are only used when a certain
     * feature is available.
     * @private
     */


    AudioserverComponent.prototype._handleFeatureCheckingReady = function _handleFeatureCheckingReady() {
        // pass in potentially stored reload delegates, as a view presenting the queue might currently be visible.
        if (!this.extensions.queueLoaderExt) {
            this.extensions.queueLoaderExt = initExtension(Components.Audioserver.extensions.QueueLoader, this, this.queueReloadDelegates);
        }

        if (!this.extensions.playlistsExt) {
            this.extensions.playlistsExt = initExtension(Components.Audioserver.extensions.PlaylistLoader, this);
        }

        if (!this.extensions.searchExt) {
            this.extensions.searchExt = initExtension(Components.Audioserver.extensions.GlobalSearchExt, this);
        }

        if (!this.extensions.dynamicGroupsExt) {
            this.extensions.dynamicGroupsExt = initExtension(Components.Audioserver.extensions.DynamicGroupsExt, this);
        }
    };

    AudioserverComponent.prototype._prepareAuthCmd =
        function _prepareAuthCmd() {
            const prepareAuthCmdTiming = timingNow();
            var token = ActiveMSComponent.getCurrentCredentials().token,
                user = ActiveMSComponent.getActiveMiniserver().activeUser,
                key = CryptoJS.lib.WordArray.random(32 / 2).toString(
                    CryptoJS.enc.Hex,
                ),
                encrypted = CryptoJS.AES.encrypt(token, key, {
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                }),
                rsaEnc = this.encryptWithPublicKey(
                    encrypted.key.toString(CryptoJS.enc.Hex) +
                        ':' +
                        encrypted.iv.toString(CryptoJS.enc.Hex) +
                        ':' +
                        this.serverInfo.sessionToken,
                ),
                cmd =
                    'secure/authenticate/' +
                    user +
                    '/' +
                    encodeURIComponent(rsaEnc) +
                    '/' +
                    encodeURIComponent(
                        encrypted.ciphertext.toString(CryptoJS.enc.Base64),
                    );
            
            if(Debug.Communication) {
                console.warn("=".repeat(50));
                const elapsed = timingDelta(prepareAuthCmdTiming);
                console.info(this.name + "  ", "pubKey -> authCmdReady: " + elapsed + "ms");
                console.warn("=".repeat(50));
            }
            return cmd;
        };

    AudioserverComponent.prototype._authenticateSocket = function _authenticate(cmd) {
        const authCmdTiming = timingNow();
        this.sendMediaServerCommand(cmd, true).then(function (res) {
            
            if (res.data === "authentication successful") {
                this.emit(this.ECEvent.DidAuthenticate);
                this.emit(this.ECEvent.ConnEstablished);
            } else {
                // authentication unsuccessful
                console.error(this.name, "_authenticationChallenge failed: " + JSON.stringify(res));

                this._handleUnauthorized(res.data);
            }
            if(Debug.Communication) {
                console.warn("=".repeat(50));
                const elapsed = timingDelta(authCmdTiming);
                console.info(this.name + "  ", "authCmdSent -> authCmdResponse: " + elapsed + "ms");
                console.warn("=".repeat(50));
            }
        }.bind(this), function (err) {
            this._handleUnauthorized(err);
        }.bind(this));
    }

    AudioserverComponent.prototype._authenticationChallenge = function _authenticationChallenge() {
        Debug.Communication && console.log(this.name, "_authenticationChallenge");
        Debug.Communication && console.log(this.name, "publicKey: " + this.publicKey);
        const getPublicKeyTiming = timingNow();
        if (this.publicKey && this.exp) {
            Debug.Communication && console.log(this.name + "   publicKey and exp already available");
            if(Debug.Communication) {
                console.warn("=".repeat(50));
                const elapsed = timingDelta(getPublicKeyTiming);
                console.info(this.name + "  ", "greeting -> getPublicKeyTiming (cached): " + elapsed + "ms");
            }
            return this._authenticateSocket(this._prepareAuthCmd());
        } else {
            Debug.Communication && console.log(this.name + "   publicKey and exp not available yet");
            return this.sendMediaServerCommand(MusicServerEnum.Commands.GETKEY, true).then(function (result) {
                if(Debug.Communication) {
                    console.warn("=".repeat(50));
                    const elapsed = timingDelta(getPublicKeyTiming);
                    console.info(this.name + "  ", "greeting -> getPublicKeyTiming: " + elapsed + "ms");
                }
                this.publicKey = result.data[0].pubkey;
                this.exp = Math.abs(result.data[0].exp).toString(16);
                const cmd = this._prepareAuthCmd();
                return this._authenticateSocket(cmd);
            }.bind(this));
        }
    };

    AudioserverComponent.prototype._handleUnauthorized = function _handleUnauthorized(err) {
        console.error(this.name, "_handleUnauthorized: " + JSON.stringify(err));
        delete this.publicKey; // it might be that this cached key is invalid, if auth error occurs again, we need to fetch a new one.
        delete this.exp;
        this.emit(this.ECEvent.DidReceiveAuthError, err);
    };
    /**
     * Once the ext-channel event connEstablished is called, a number of commands are sent to gather information
     * on the Media-Server on the other side of the connection.
     * @private
     */


    AudioserverComponent.prototype._handleConnEstablished = function _handleConnEstablished() {
        // store a reference to the deferred, as due to JS being "frozen" on locking iOS devices, we need an option to
        // check after requesting the states, if the connection attempt is still ongoing.
        var currentConnReadyDeferred = this.connectionReadyDef;console.log(this.name, "_handleConnEstablished!"); // Request the states of every player

        var prms = this.getAvailableZones(true).filter(function (zone) {
            // We only want to get the state of the current server
            return zone.details.server === this.serverUuid;
        }.bind(this)).map(function (zone) {
            return this.sendMediaServerCommand("audio/" + zone.details.playerid + "/status", true).then(function (result) {
                var control = this.getControlByPlayerId(result.data[0].playerid || zone.details.playerid),
                    stateContainer = SandboxComponent.getStateContainerForUUID(control.uuidAction);
                stateContainer && stateContainer.injectStates(result.data[0]);
                return zone;
            }.bind(this));
        }.bind(this));
        return Q.all(prms).then(function (zones) {
            zones.forEach(zone => require("FavoritesManager").shared(zone));
        }.bind(this)).finally(function () {
            // on iOS devices, when unlocking/locking the app at the wrong time, JS events are postboned and processed
            // when the device is woken up again. This may lead to a problem, as this might resolve the connectionReadyDeferred
            // which isn't the same as we've had before and isn't authenticated yet.
            if (this.connectionReadyDef !== currentConnReadyDeferred) {// nothing to do, as this is the result of an old connection attempt that has responded/failed.
            } else {
                this.connectionReadyDef.resolve();

                this._checkExtensions(); // This is not a mandatory command, don't delay the start of the audio player with this command


                this.sendMediaServerCommand(MusicServerEnum.Commands.SYNC.GET_SYNCED_PLAYERS, true);
            }
        }.bind(this));
    };
    /**
     * Only to be used if th MEDIA connection is closed, not the Miniservers.
     * Once a connection is closed, the queueLoader extension is to be destroyed.
     * @private
     */


    AudioserverComponent.prototype._handleConnClosed = function _handleConnClosed(ev, evArg) {
        // don't overwrite an existing connectionReadyDeferred - otherwise some views will never load their data as
        // the promise will be pending forever
        if (!this.connectionReadyDef || !this.connectionReadyDef.promise.isPending()) {
        this.connectionReadyDef = Q.defer();
} else {
            this.connectionReadyDef.notify(evArg);
        }
        if (this.extensions.queueLoaderExt) {
            // destroy the queue loader ext as it will be re-initialized once the new feature checking is ready.
            // store the reload delegates for later, e.g. when the queue is currently being viewed, while the conn drops.
            this.queueReloadDelegates = this.extensions.queueLoaderExt.getReloadDelegates();
            this.extensions.queueLoaderExt.destroy();
            delete this.extensions.queueLoaderExt;
        } // Dispatch stopped - otherwise e.g. the media listing won't reload a potentially updated content on the RESUMED event


        this.emit(this.ECEvent.ResetMediaApp, {
            stopped: true,
            from: "connClosed"
        }); // invalidate all caches, but don't reload!
    };
    /**
     * Since the AudioserverComp itself uses it's extensions to communicate with the MediaServer, it listens to the
     * result-received event and processes it.
     * @param evId      static "ResultReceived"
     * @param result    the result received on the socket / proxy.
     * @private
     */


    AudioserverComponent.prototype._handleResult = function _handleResult(evId, result) {
        if (!this.commands || !this.commands.hasOwnProperty(result.command)) {
            Debug.Media.Component && console.log("AudioserverComp doesn't handle the result: " + JSON.stringify(result));
            return;
        }

        Debug.Media.Component && console.log("result received! " + result.command);
        var deferreds = this.commands[result.command];

        for (var i = 0; i < deferreds.length; i++) {
            try {
                deferreds[i].resolve(result);
            } catch (exc) {
                deferreds[i].reject("Error while resolving");
            }
        }

        delete this.commands[result.command];
    };
    /**
     * Since the AudioserverComp itself uses it's extensions to communicate with the MediaServer, it listens to the
     * result-received event and processes it.
     * @param evId      static "ResultErrorReceived"
     * @param result    the result received on the socket / proxy.
     * @private
     */


    AudioserverComponent.prototype._handleErrorResult = function _handleErrorResult(evId, result) {
        if (!this.commands || !this.commands.hasOwnProperty(result.command)) {
            Debug.Media.Component && console.log("AudioserverComp doesn't handle the error result: " + JSON.stringify(result));
            return;
        }

        Debug.Media.Component && console.log("error result received! " + result.command);
        var deferreds = this.commands[result.command];

        for (var i = 0; i < deferreds.length; i++) {
            try {
                deferreds[i].reject(result);
            } catch (exc) {
                deferreds[i].reject("Error while resolving");
            }
        }

        delete this.commands[result.command];
    };
    /**
     * Extensions are being torn down and set right back up when a MS-Session is started. Calling it again without
     * destroying the extensions before does not have any affect.
     * @private
     */


    AudioserverComponent.prototype._checkExtensions = function _checkExtensions() {
        if (Object.keys(this.extensions).length > 0) {
            return;
        }

        Debug.Media.Component && console.log("AudioserverComp: _checkExtensions");

        if (!this.extensions.hasOwnProperty("centralCommunicationNode")) {
            this.extensions.centralCommunicationNode = initExtension(Components.Audioserver.extensions.CentralCommunicationNode, this);
        }

        if (!this.extensions.hasOwnProperty("mediaMessageParserExt")) {
            this.extensions.mediaMessageParserExt = initExtension(Components.Audioserver.extensions.MediaMessageParser, this);
        }

        if (!this.extensions.hasOwnProperty("audioZoneExt")) {
            this.extensions.audioZoneExt = initExtension(Components.Audioserver.extensions.AudioZone, this, this.audioZoneExtListenerObj);
        }

        if (!this.extensions.hasOwnProperty("zoneFavoritesExt")) {
            this.extensions.zoneFavoritesExt = initExtension(Components.Audioserver.extensions.RoomFavoriteLoader, this);
        }

        if (!this.extensions.hasOwnProperty("zoneGroupExt")) {
            this.extensions.zoneGroupExt = initExtension(Components.Audioserver.extensions.ZoneGroup, this);
        }

        if (!this.extensions.hasOwnProperty("voiceRecorderExt")) {
            this.extensions.voiceRecorderExt = initExtension(Components.Audioserver.extensions.VoiceRecorderExt, this);
        }

        if (!this.extensions.hasOwnProperty("popupExt")) {
            this.extensions.popupExt = initExtension(Components.Audioserver.extensions.PopupExt, this);
        }

        if (!this.extensions.hasOwnProperty("dialogExt")) {
            this.extensions.dialogExt = initExtension(Components.Audioserver.extensions.DialogExt, this);
        }

        if (!this.extensions.hasOwnProperty("serviceHandlerExt")) {
            this.extensions.serviceHandlerExt = initExtension(Components.Audioserver.extensions.ServiceHandlerExt, this);
        }

        if (!this.extensions.hasOwnProperty("inputExt")) {
            this.extensions.inputExt = initExtension(Components.Audioserver.extensions.InputExt, this);
        }

        if (!this.extensions.hasOwnProperty("inputLoaderExt")) {
            this.extensions.inputLoaderExt = initExtension(Components.Audioserver.extensions.InputLoader, this);
        }

        if (!this.extensions.hasOwnProperty("zoneFavExt")) {
            this.extensions.zoneFavExt = initExtension(Components.Audioserver.extensions.ZoneFavoriteExt, this);
        }

        if (!this.extensions.hasOwnProperty("libraryExt")) {
            this.extensions.libraryExt = initExtension(Components.Audioserver.extensions.LibraryLoader, this);
        } // queueLoaderExt && playlistLoaderExt will be initialized once the feature checking is ready.


        if (!this.extensions.hasOwnProperty("servicesExt")) {
            this.servicesExt = initExtension(Components.Audioserver.extensions.ServiceLoader, this);
        }

        if (!this.extensions.hasOwnProperty("soundsuitExt")) {
            this.extensions.soundsuitExt = initExtension(Components.Audioserver.extensions.SoundsuitLoader, this);
        }

        if (!this.extensions.hasOwnProperty("searchDetailExt")) {
            this.extensions.searchDetailExt = initExtension(Components.Audioserver.extensions.SearchDetailLoader, this);
        }

        if (!this.extensions.hasOwnProperty("audioZoneSettingsExt")) {
            this.extensions.audioZoneSettingsExt = initExtension(Components.Audioserver.extensions.AudioZoneSettings, this);
        }

        if (!this.extensions.hasOwnProperty("dynamicGroupsExt") && this.supportsDynamicGroups()) {
            this.extensions.dynamicGroupsExt = initExtension(Components.Audioserver.extensions.DynamicGroupsExt, this);
        }
    };

    AudioserverComponent.prototype._destroySingletones = function _destroySingletones(control) {
        if (Controls.AudioZoneV2Control.SingleTones) {
            Object.keys(Controls.AudioZoneV2Control.SingleTones).forEach(function (singleToneName) {
                try {
                    Controls.AudioZoneV2Control.SingleTones[singleToneName].destroy(control);
                } catch (e) {
                    console.warn("Couldn't destroy singletone '" + singleToneName + "' for control '" + control.uuidAction + "'", e);
                }
            }.bind(this));
        }
    };
    /**
     * Will destroy all extensions, ensures nothing is happening while communicating/changeing to a miniserver without
     * a MediaServer in its config.
     * @private
     */


    AudioserverComponent.prototype._destroyExtensions = function _destroyExtensions() {
        Debug.Media.Component && console.log("_destroyExtensions");
        Object.keys(this.extensions).forEach(function (key) {
            var extension = this.extensions[key];

            try {
                extension && extension.destroy();
            } catch (ex) {
                console.error("Could not destroy the AudioserverComp-Extension '" + key + "'");
                console.error(ex);
            }
        }.bind(this));
        this.extensions = {};
    };
    /**
     * Update the attribute & emit the corresponding ext-channel event.
     * @private
     */


    AudioserverComponent.prototype._handleTaskRecorderStart = function _handleTaskRecorderStart() {
        this.taskRecorderActive = true;
        this.emit(this.ECEvent.TaskRecorderStart);
    }; // ---------------------------------------------------------------------------
    //                    Public Methods
    // ---------------------------------------------------------------------------


    AudioserverComponent.prototype.setConnectionUrl = function setConnectionUrl(url) {
        this.connectionUrl = url;
    };
    /**
     * Sets the Peer to Peer status of the current connection
     * This is mainly used to differentiate if we use a Proxy between the App and the Audioserver (Miniserver Proxy via remote connection)
     * @param isP2P
     */


    AudioserverComponent.prototype.setIsP2PConnection = function setIsP2PConnection(isP2P) {
        this.isP2PConnection = !!isP2P;
    };
    /**
     * Returns weather or not the AudioserverComp is currently active (= communication runs through it). This is not the case
     * as long as no audioZone is active and multi music server is in use. Otherwise it's always true;
     * @returns {*}
     */


    AudioserverComponent.prototype.isActive = function isActive() {
        var result = true;

        if (Feature.MULTI_MUSIC_SERVER) {
            result = this.activeZoneControl !== null;
        }

        return result;
    };
    /**
     * Returns the MediaServerObject that corresponds to the active zone.
     * @returns {*}
     */


    AudioserverComponent.prototype.getActiveMediaServer = function getActiveMediaServer() {
        return this.getMediaServer(this.serverUuid);
    };
    /**
     * True if the active audio service is running on a compact.
     * @returns {boolean}
     */


    AudioserverComponent.prototype.isCompactAudioservice = function isCompactAudioservice() {
        return this.getAudioserverSubtype() === MediaServerSubType.COMPACT;
    };
    /**
     * Returns the subType of the audio server (may be running on a compact)
     * @returns {boolean}
     */


    AudioserverComponent.prototype.getAudioserverSubtype = function getAudioserverSubtype() {
        var activeAudioService = this.getActiveMediaServer(),
            subType = MediaServerSubType.AUDIOSERVER;

        if (activeAudioService && activeAudioService.hasOwnProperty("subType")) {
            subType = activeAudioService.subType;
        }

        return subType;
    };
    /**
     * Looks up a certain Music Server from the structure manager.
     * @param svrUuid   the uuid of the server we're looking for.
     * @returns {*}
     */


    AudioserverComponent.prototype.getMediaServer = function getMediaServer(svrUuid) {
        var wantedServer = null;

        try {
            var servers = ActiveMSComponent.getStructureManager().getMediaServerSet();

            if (servers != null) {
                var keys = Object.keys(servers);

                for (var i = 0; i < keys.length; i++) {
                    var candidate = servers[keys[i]];

                    if ((candidate.type === MediaServerType.LXMUSIC || candidate.type === MediaServerType.LXMUSIC_V2) && (!svrUuid || svrUuid === candidate.uuidAction)) {
                        wantedServer = candidate;
                        break; // we've found him, lets get outta here.
                    }
                }
            }
        } catch (e) {
            console.error("Error while looking for the Loxone Multimedia Server!");
            console.error(e.stack);
        }

        if (this._isCompactMs(wantedServer) && wantedServer && wantedServer.mac.toLowerCase() === ActiveMSComponent.getActiveMiniserver().serialNo.toLowerCase()) {
            wantedServer.name = ActiveMSComponent.getActiveMiniserver().msName;
        }

        return wantedServer;
    };

    AudioserverComponent.prototype._isCompactMs = function _isCompactMs(wantedServer) {
        return wantedServer && wantedServer.mac.replace(/:/g, "").toUpperCase().hasPrefix(MusicServerEnum.COMPACT_MAC_PRFX);
    };
    /**
     * Looks up a certain Music Server from the structure manager.
     * @param svrSerial   the serial number of the server we're looking for.
     * @returns {*}
     */


    AudioserverComponent.prototype.getMediaServerBySerial = function getMediaServerBySerial(svrSerial) {
        var wantedServer = null;

        if (!svrSerial) {
            console.error(this.name, "getMediaServerBySerial: " + svrSerial + " - ERROR, no serial provided!", getStackObj());
            return wantedServer;
        }

        try {
            var servers = ActiveMSComponent.getStructureManager().getMediaServerSet();

            if (servers != null) {
                var keys = Object.keys(servers);

                for (var i = 0; i < keys.length; i++) {
                    var candidate = servers[keys[i]];

                    if ((candidate.type === MediaServerType.LXMUSIC || candidate.type === MediaServerType.LXMUSIC_V2) && (!svrSerial || svrSerial === candidate.mac)) {
                        wantedServer = candidate;
                        break; // we've found him, lets get outta here.
                    }
                }
            }
        } catch (e) {
            console.error("Error while looking for the Loxone Multimedia Server!");
            console.error(e.stack);
        }

        if (this._isCompactMs(wantedServer) && wantedServer && wantedServer.mac.toLowerCase() === ActiveMSComponent.getActiveMiniserver().serialNo.toLowerCase()) {
            wantedServer.name = ActiveMSComponent.getActiveMiniserver().msName;
        }

        return wantedServer;
    };
    /**
     * Returns the name of a media server. Either the active one or specified by the svrUuid
     * @param svrUuid   optional - the uuid of the server, default active server.
     * @param [serverObj]   optional - the server object itself
     * @returns {*}
     */


    AudioserverComponent.prototype.getServerName = function getServerName(svrUuid, serverObj) {
        var mediaServ;

        if (serverObj) {
            mediaServ = serverObj;
        } else if (svrUuid) {
            mediaServ = this.getMediaServer(svrUuid);
        } else {
            mediaServ = this.getActiveMediaServer();
        }

        if (!mediaServ) {
            console.error("Won't show proper server name for server with uuid: " + svrUuid);
        }

        return mediaServ ? mediaServ.name : _("audio-server.popup.title");
    };

    AudioserverComponent.prototype.getServerInfo = function getServerInfo() {
        return this.serverInfo;
    };

    AudioserverComponent.prototype.getServerFirmwareVersion = function getServerFirmwareVersion() {
        return this.serverInfo ? this.serverInfo.firmwareVersion : null;
    };

    AudioserverComponent.prototype.getServerSerialNumber = function getServerSerialNumber() {
        return this.getMediaServer(this.serverUuid).mac.replace(/:/g, "");
    };

    AudioserverComponent.prototype.getActiveZoneControl = function getActiveZoneControl() {
        return this.activeZoneControl;
    };

    AudioserverComponent.prototype.getActiveSpotifyAccountManager = function getActiveSpotifyAccountManager() {
        return Q.resolve(require("SpotifyAccountManager").shared(this.activeZoneControl));
    };

    AudioserverComponent.prototype.isSpotifyLikedSongsItem = function isSpotifyLikedSongsItem(item) {
        var path = item[MusicServerEnum.Event.AUDIO_PATH];
        return path && path.startsWith(MusicServerEnum.Spotify.PathParts.FavSongs.PREFIX) && path.endsWith(MusicServerEnum.Spotify.PathParts.FavSongs.SUFFIX);
    };

    AudioserverComponent.prototype.getSpotifyLikedSongsItem = function getSpotifyLikedSongsItem(userId, userName) {
        return {
            id: MusicServerEnum.Spotify.TYPES.TRACKS,
            name: _("media.spotify.favorite-songs"),
            owner: userName,
            ownerId: userId,
            owner_id: userId,
            type: MusicServerEnum.FileType.PLAYLIST_BROWSABLE,
            audiopath: MusicServerEnum.Spotify.PathParts.FavSongs.PREFIX + userId + MusicServerEnum.Spotify.PathParts.FavSongs.SUFFIX,
            tag: MusicServerEnum.Tag.PLAYLIST,
            isSpotify: true
        };
    };

    AudioserverComponent.prototype.playFileUrl = function playFileUrl(item, mediaInfo) {
        var cmd = AudioserverComp.getPlayCommandForItem(item, MusicServerEnum.PlayType.REPLACE, item.contentType, mediaInfo),
            result = false;

        if (cmd) {
            result = AudioserverComp.sendAudioZoneCommand(AudioserverComp.activeZoneControl.details[MusicServerEnum.Event.PLAYER_ID], {
                cmd: cmd
            });
        }

        return result;
    };
    /**
     * Returns the ip of a media server. Either the active one or specified by the svrUuid
     * @param svrUuid   optional - the uuid of the server, default active server.
     * @returns {*}
     */


    AudioserverComponent.prototype.getServerIp = function getServerIp(svrUuid) {
        var mediaServ;

        if (svrUuid) {
            mediaServ = this.getMediaServer(svrUuid);
        } else {
            mediaServ = this.getActiveMediaServer();
        }

        var host = mediaServ.host;
        return host.split(":")[0];
    };
    /**
     * Returns an rsa-encrypted cipher of the payload provided. The public key of the Media-Server is used
     * to encrypt the payload. This way only the Media-Server is capable of decrypting it.
     * @param payload
     * @returns {*}
     */


    AudioserverComponent.prototype.encryptWithPublicKey = function encryptWithPublicKey(payload, addNonce = false) {
        var encrypt = new JSEncrypt();
        encrypt.getKey().setPublic(this.publicKey, this.exp);
        if (addNonce) {
            // adds a 64-hex (=32 byte) nonce to the encryption, removing zeros to avoid string-terminations.
            let nonce = CryptoJS.lib.WordArray.random(32)
                .toString()
                .replaceAll("0", getRandomIntInclusive(1,9));
            //console.log(this.name, "encryptWithPublicKey: nonce=true --> (" + payload + nonce + ")");
            return encrypt.encrypt(payload + nonce);
        } else {
            //console.log(this.name, "encryptWithPublicKey: nonce=false --> (" + payload + ")");
            return encrypt.encrypt(payload);
        }
    };

    AudioserverComponent.prototype.getServerStateStr = function getServerStateStr(serverState) {
        var serverStateStr = "--undefined--";

        switch (serverState) {
            case MusicServerEnum.ServerState.ONLINE:
                serverStateStr = "Online";
                break;

            case MusicServerEnum.ServerState.OFFLINE:
                serverStateStr = "Offline";
                break;

            case MusicServerEnum.ServerState.INITIALIZING:
                serverStateStr = "Initializing";
                break;

            case MusicServerEnum.ServerState.INVALID_ZONE:
                serverStateStr = "Invalid Zone";
                break;

            case MusicServerEnum.ServerState.NOT_REACHABLE:
                serverStateStr = "Not Reachable";
                break;

            case MusicServerEnum.ServerState.MUSICSERVER_REBOOTING:
                serverStateStr = "Musicserver Rebooting";
                break;

            default:
                serverStateStr = "-unknown- " + states.serverState;
                break;
        }

        return serverStateStr;
    };
    /**
     * the AudioserverComp needs to know whether or not any (fullscreen) audio-views are visible, e.g. for the popupExt
     * or when an app-reload event is received.
     * @param visible       Whether or not there are any audioViews visible
     * @param [zoneControl] the zone that was opened
     * @param [viewCtrl]    The viewController presenting the audio-views, only provided if visible is true.
     */


    AudioserverComponent.prototype.setAudioViewsVisible = function setAudioViewsVisible(visible, zoneControl, viewCtrl) {
        Debug.Media.Component && console.log("AudioserverComp setAudioViewsVisible: " + JSON.stringify(visible));

        if (visible) {
            Debug.Media.Component && console.log(this.name, "   an audioZone became visible, activate!");
            window.AudioserverComp = zoneControl.audioserverComp;

            require("HardwareVolumeButtonHandlerV2").shared().start(zoneControl);
        }

        this._handleAudioViewsVisibleMulti(visible, zoneControl, viewCtrl); // don't use the "visible"-argument passed in. If an audioZone is opened on top of another (linked control) this would cause issues


        if (!this.audioViewsVisible) {
            Debug.Media.Component && console.log(this.name, "   no audioView is visible anymore, reset");
            window.AudioserverComp = AudioserverComponent;

            this._destroySingletones(zoneControl);

            this.emit(this.ECEvent.ResetMediaApp, {
                stopped: false,
                from: "viewVisible"
            }); // invalidate all caches, but don't reload!
        }

        CompChannel.emit(CCEvent.MUSIC_ZONE_CHANGED, zoneControl.details.server);
    };

    AudioserverComponent.prototype.stopVolumeButtonInterception = function stopVolumeButtonInterception() {
        Controls.AudioZoneV2Control.SingleTones.HardwareVolumeButtonHandlerV2.shared().stop();
    };

    AudioserverComponent.prototype.startVolumeButtonInterception = function startVolumeButtonInterception() {
        if (this.activeZoneControl) {
            Controls.AudioZoneV2Control.SingleTones.HardwareVolumeButtonHandlerV2.shared().start(this.activeZoneControl);
        }
    };
    /**
     * When Multiple Music Servers are supported, the connection handling will depend on setAudioViewsVisible.
     * @param visible       Whether or not there are any audioViews visible
     * @param [zoneControl] the zone that was opened
     * @param [viewCtrl]    The viewController presenting the audio-views, only provided if visible is true.
     */


    AudioserverComponent.prototype._handleAudioViewsVisibleMulti = function _handleAudioViewsVisibleMulti(visible, zoneControl, viewCtrl) {
        if (this._isAmbientViewOverlayAppear(...arguments)) {
            this._overlayedViewController = this.AudioViewController;
            this.AudioViewController = viewCtrl;

        } else if (this._isAmbientOverlayDisappear(...arguments)) {
            this.AudioViewController = this._overlayedViewController;
            this._overlayedViewController = null;

        } else if (visible) {
            this.audioViewsVisible = true;
            this.visibleControlUuid = zoneControl.uuidAction;
            Debug.Media.Component && console.log(this.name, "_handleAudioViewsVisibleMulti --> TRUE " + zoneControl.getName(), getStackObj());
            this.activeZoneControl = zoneControl;
            this.AudioViewController = viewCtrl;
            this.emit(this.ECEvent.InitialAudioScreenLoaded);
            this.emit(this.ECEvent.ZoneChanged, zoneControl);

        } else if (this.visibleControlUuid !== zoneControl.uuidAction) {
            Debug.Media.Component && console.log(this.name, "_handleAudioViewsVisibleMulti --> FALSE, but already another audioZone visible! " + zoneControl.getName(), getStackObj());

        } else if (this.AudioViewController === viewCtrl) {
            this.audioViewsVisible = false;
            Debug.Media.Component && console.log(this.name, "_handleAudioViewsVisibleMulti --> FALSE " + zoneControl.getName(), getStackObj());
            this.AudioViewController = null;
            this.activeZoneControl = null;
            this.visibleControlUuid = null;
        }
    };


    AudioserverComponent.prototype._isAmbientViewOverlayAppear = function _isAmbientViewOverlayAppear(visible, zoneControl, viewCtrl) {
        return !this._overlayedViewController && visible && this.audioViewsVisible
            && zoneControl
            && this.visibleControlUuid === zoneControl.uuidAction
            && viewCtrl !== this.AudioViewController;
    }

    AudioserverComponent.prototype._isAmbientOverlayDisappear = function _isAmbientOverlayDisappear(visible, zoneControl, viewCtrl) {
        return this._overlayedViewController &&
            !visible && this.audioViewsVisible
            && zoneControl
            && this.visibleControlUuid === zoneControl.uuidAction
            && viewCtrl !== this.AudioViewController;
    }

    /**
     * Gives info on if there are any audioViews visible at the moment.
     * @param [zoneControl] if provided, it checks if that very zone is currently visible.
     * @returns {boolean|*}
     */
    AudioserverComponent.prototype.getAudioViewsVisible = function getAudioViewsVisible(zoneControl) {
        var isVisible = this.audioViewsVisible;

        if (zoneControl && isVisible) {
            isVisible = zoneControl.uuidAction === this.visibleControlUuid;
        }

        return isVisible;
    };

    AudioserverComponent.prototype.rebootAudioServer = function rebootAudioServer(zoneCtrl) {
        var isCompact = this.getServerSerialNumber().toUpperCase().hasPrefix(MusicServerEnum.COMPACT_MAC_PRFX);

        if (isCompact) {
            return CommunicationComponent.send(Commands.REBOOT_AUDIO_SERVICES);
        } else if (this.isConnectionPromisePending()) {
            NavigationComp.showPopup({
                title: _("miniserver.not-connected"),
                buttonCancel: _("okay")
            });
        } else {
            return this.sendMediaServerCommand("audio/cfg/reboot");
        }
    };
    /**
     * Sends a command to the MediaServer, does not prequel "audio/2" or alike.
     * @param command       the command to send
     * @param background    if a command is sent in the background, it'll not be minded by the task-recorder.
     * @returns {deferred.promise|{then, catch, finally}}
     */


    AudioserverComponent.prototype.sendMediaServerCommand = function sendMediaServerCommand(command, background) {
        var deferred = Q.defer();
        Debug.Communication && CommTracker.track(deferred.promise, CommTracker.Transport.AUDIO_SOCKET, command);
        this.emit(this.ECEvent.SendMessage, {
            cmd: command,
            auto: !!background
        });

        if (!this.commands) {
            this.commands = {};
        }

        if (!this.commands[command]) {
            this.commands[command] = [];
        }

        this.commands[command].push(deferred);
        return deferred.promise;
    };
    /**
     * Sends an http request to the mediaServer of the zone
     * @param control       the control object representing the zone
     * @param cmd           the command to send, it will be prequeled by "audio/PLAYERID/"
     * @param [background]  whether or not this command is to be sent via background (important for task-recording).
     * @returns {deferred.promise|{then, catch, finally}}
     */


    AudioserverComponent.prototype.sendHttpPlayerCommand = function sendHttpPlayerCommand(control, cmd, background) {
        var deferred = Q.defer();
        Debug.Communication && CommTracker.track(deferred.promise, CommTracker.Transport.AUDIO_HTTP, cmd);

        if (!this.taskRecorderActive) {
            // first get the targeted mediaServer
            var server = ActiveMSComponent.getStructureManager().getMediaServerSet()[control.details.server]; // then send the command

            var fullCmd = "http://" + server.host + "/audio/" + control.details.playerid + "/" + cmd;
            $.ajax({
                url: fullCmd,
                context: this,
                success: function () {
                    deferred.resolve();
                },
                error: function (err) {
                    console.error("Error: cmd: " + cmd);
                    console.error(err);
                    deferred.reject();
                },
                crossDomain: true
            });
        } else {
            // while task-recording is active, use the sandbox, it'll take care of the task recording.
            SandboxComponent.sendCommand(this, control.uuidAction, cmd, null, control.isSecured, null, background);
            setTimeout(function () {
                deferred.resolve();
            }, 1);
        }

        return deferred.promise;
    };
    /**
     * Returns the play command for an item. Every item can be played in 4 different ways, specified by the PlayType-Enum.
     * @param item              the item to play
     * @param playType          "how" to play it (now, next, ..)
     * @param contentType       where the item is from
     * @param mediaTypeDetails  service information of the item.
     * @returns {string}        the fully qualified command for playing an item.
     */


    AudioserverComponent.prototype.getPlayCommandForItem = function getPlayCommandForItem(item, playType, contentType, mediaTypeDetails) {
        var cmd = null;

        switch (contentType) {
            case MusicServerEnum.MediaContentType.LIBRARY:
                cmd = this._getLibraryPlayCommandForItem(item, playType);
                break;

            case MusicServerEnum.MediaContentType.PLAYLISTS:
                cmd = this._getPlaylistPlayCommandForItem(item, playType, mediaTypeDetails);
                break;

            case MusicServerEnum.MediaContentType.SERVICE:
                cmd = this._getServicePlayCommandForItem(item, playType, mediaTypeDetails);
                break;

            case MusicServerEnum.MediaContentType.SOUNDSUIT:
                cmd = this._getServicePlayCommandForItem(item, playType, mediaTypeDetails, false);
                break;

            case MusicServerEnum.MediaContentType.SEARCH:
                cmd = this._getSearchPlayCommandForItem(item, playType);
                break;

            case MusicServerEnum.MediaContentType.FAVORITES:
                cmd = this._getFavoritePlayCommandForItem(item, playType);
                break;

            case MusicServerEnum.MediaContentType.ZONE_FAVORITES:
                cmd = this._getZoneFavoritePlayCommandForItem(item, playType);
                break;

            case MusicServerEnum.MediaContentType.QUEUE:
                cmd = this._getQueuePlayCommand(item, playType);
                break;

            case MusicServerEnum.MediaContentType.LINEIN:
                cmd = this._getInputPlayCommand(item, playType);
                break;

            case MusicServerEnum.MediaContentType.PLAYING:
                cmd = null; // it is playing right now - what do we want?

                break;

            default:
                throw new Error("Could not acquire a play command for the contentType " + contentType);
        }

        return cmd;
    };

    AudioserverComponent.prototype._getSearchPlayCommandForItem = function _getSearchPlayCommandForItem(item, playType) {
        var cmd = null;

        switch (playType) {
            case MusicServerEnum.PlayType.NOW:
                cmd = MusicServerEnum.AudioCommands.SEARCH.INSERT_AND_PLAY;
                break;

            case MusicServerEnum.PlayType.NEXT:
                cmd = MusicServerEnum.AudioCommands.SEARCH.INSERT;
                break;

            case MusicServerEnum.PlayType.REPLACE:
                cmd = MusicServerEnum.AudioCommands.SEARCH.PLAY;
                break;

            case MusicServerEnum.PlayType.ADD:
                cmd = MusicServerEnum.AudioCommands.SEARCH.ADD;
                break;

            default:
                cmd = null;
                break;
        }

        cmd += this._getItemId(item);
        return cmd;
    };

    AudioserverComponent.prototype._getQueuePlayCommand = function _getQueuePlayCommand(item, playType) {
        var cmd = null;

        switch (playType) {
            case MusicServerEnum.PlayType.NOW:
                cmd = MusicServerEnum.AudioCommands.QUEUE.JUMP_TO;
                break;

            default:
                cmd = null;
                break;
        }

        if (cmd) {
            cmd += item[MusicServerEnum.Event.QUEUE_INDEX];
        }

        return cmd;
    };

    AudioserverComponent.prototype._getInputPlayCommand = function _getInputPlayCommand(item, playType) {
        var cmd = null;

        switch (playType) {
            case MusicServerEnum.PlayType.REPLACE:
                cmd = MusicServerEnum.AudioCommands.LINEIN.PLAY;
                break;

            default:
                cmd = null;
                break;
        }

        if (cmd && item[MusicServerEnum.Event.ID]) {
            cmd += "/" + item[MusicServerEnum.Event.ID];
        }

        return cmd;
    };

    AudioserverComponent.prototype._getFavoritePlayCommandForItem = function _getFavoritePlayCommandForItem(item, playType) {
        var cmd = null;

        switch (playType) {
            case MusicServerEnum.PlayType.NOW:
                cmd = MusicServerEnum.AudioCommands.QUEUE.INSERT_AND_PLAY;
                break;

            case MusicServerEnum.PlayType.NEXT:
                cmd = MusicServerEnum.AudioCommands.QUEUE.INSERT;
                break;

            case MusicServerEnum.PlayType.ADD:
                cmd = MusicServerEnum.AudioCommands.QUEUE.ADD;
                break;

            case MusicServerEnum.PlayType.REPLACE:
                cmd = MusicServerEnum.AudioCommands.FAVORITE.PLAY;
                break;

            default:
                cmd = null;
                break;
        }

        if (cmd) {
            cmd += this._getItemId(item);
        }

        return cmd;
    };

    AudioserverComponent.prototype._getZoneFavoritePlayCommandForItem = function _getZoneFavoritePlayCommandForItem(item, playType) {
        var cmd = null;

        switch (playType) {
            case MusicServerEnum.PlayType.REPLACE:
                cmd = MusicServerEnum.AudioCommands.ZONE_FAV.PLAY;
                break;

            default:
                cmd = null;
                break;
        }

        if (cmd) {
            // zone favorites have slots!
            cmd += item[MusicServerEnum.Event.ID];
        }

        return cmd;
    };

    AudioserverComponent.prototype._getLibraryPlayCommandForItem = function _getLibraryPlayCommandForItem(item, playType) {
        var cmd = null;

        switch (playType) {
            case MusicServerEnum.PlayType.NOW:
                cmd = MusicServerEnum.AudioCommands.QUEUE.INSERT_AND_PLAY;
                break;

            case MusicServerEnum.PlayType.NEXT:
                cmd = MusicServerEnum.AudioCommands.QUEUE.INSERT;
                break;

            case MusicServerEnum.PlayType.REPLACE:
                cmd = MusicServerEnum.AudioCommands.LIBRARY.PLAY;
                break;

            case MusicServerEnum.PlayType.ADD:
                cmd = MusicServerEnum.AudioCommands.QUEUE.ADD;
                break;
        }

        cmd += this._getItemId(item);
        return cmd;
    };
    /**
     * When it comes to playlists, there is only playtype, replace queue.
     * @param item
     * @param playType
     * @param mediaTypeDetails
     * @returns {*}
     * @private
     */


    AudioserverComponent.prototype._getPlaylistPlayCommandForItem = function _getPlaylistPlayCommandForItem(item, playType, mediaTypeDetails) {
        var cmd = null,
            service = mediaTypeDetails ? mediaTypeDetails[MusicServerEnum.Attr.SERVICE._] : null,
            isService = service && service[MusicServerEnum.Attr.SERVICE.CMD] !== MusicServerEnum.Target.LMS; // playing playlists from services works the same way as if we play them from getservicesfolder!

        if (isService) {
            return this._getServicePlayCommandForItem(item, playType, mediaTypeDetails);
        } else {
            switch (playType) {
                case MusicServerEnum.PlayType.REPLACE:
                    cmd = MusicServerEnum.AudioCommands.PLAYLIST.PLAY;
                    break;
            }

            if (cmd) {
                cmd += this._getItemId(item, true);
            }
        }

        return cmd;
    };

    AudioserverComponent.prototype._getServicePlayCommandForItem = function _getServicePlayCommandForItem(item, playType, mediaTypeDetails, preferId = true) {
        var cmd = null,
            service;

        switch (playType) {
            case MusicServerEnum.PlayType.NOW:
                cmd = MusicServerEnum.AudioCommands.SERVICE.INSERT_AND_PLAY;
                break;

            case MusicServerEnum.PlayType.NEXT:
                cmd = MusicServerEnum.AudioCommands.SERVICE.INSERT;
                break;

            case MusicServerEnum.PlayType.REPLACE:
                cmd = MusicServerEnum.AudioCommands.SERVICE.PLAY;
                break;

            case MusicServerEnum.PlayType.ADD:
                cmd = MusicServerEnum.AudioCommands.SERVICE.ADD;
                break;
        }

        service = mediaTypeDetails[MusicServerEnum.Attr.SERVICE._];

        if (!service) {
            cmd += "null/noUser"; // no service provided --> assume empty service identifier.
        } else if (service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.UID)) {
            cmd += service[MusicServerEnum.Attr.SERVICE.UID];
        } else if (service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.CMD)) {
            cmd += service[MusicServerEnum.Attr.SERVICE.CMD];

            if (service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.USER)) {
                cmd += "/" + service[MusicServerEnum.Attr.SERVICE.USER];
            } else {
                cmd += "/" + MusicServerEnum.NOUSER;
            }
        }

        cmd += "/" + this._getItemId(item, preferId);
        return cmd;
    };
    /**
     * Returns either an items path or an items ID. Path has priority.
     * @param item      the item in question
     * @param [idFirst] if specified, the id has priority
     * @returns {*}     either the path (if available), otherwise the ID.
     * @private
     */


    AudioserverComponent.prototype._getItemId = function _getItemId(item, idFirst) {
        var id = null;

        if (idFirst && item.hasOwnProperty(MusicServerEnum.Event.ID) && !this.isSpotifyLikedSongsItem(item)) {
            id = item[MusicServerEnum.Event.ID];
        } else if (item.hasOwnProperty(MusicServerEnum.Event.AUDIO_PATH) && item[MusicServerEnum.Event.AUDIO_PATH] !== "") {
            id = item[MusicServerEnum.Event.AUDIO_PATH];
        } else if (item.hasOwnProperty(MusicServerEnum.Event.ID)) {
            id = item[MusicServerEnum.Event.ID];
        } else {
            throw new Error("The item " + JSON.stringify(item) + " has neither got an audioPath nor an ID!");
        }

        return id;
    };
    /**
     * Returns the specific popup (context menue) buttons for a playlist.
     * @param item              the item in question
     * @param mediaTypeDetails  details such as the source service of the item
     * @param rmCB              the callback when remove/delete/unfollow was selcted
     * @param editCB            callback for editing the playlist
     * @param renameCB          callback when renaming was selected.
     * @returns {Array}
     */


    AudioserverComponent.prototype.getPlaylistContextMenuButtons = function getPlaylistContextMenuButtons(item, mediaTypeDetails, rmCB, editCB, renameCB) {
        var buttons = [];

        if (item[MusicServerEnum.Event.FILE_TYPE] === MusicServerEnum.FileType.PLAYLIST_EDITABLE) {
            // contentOld-type/service specific actions
            //TODO-woessto: renaming playlists isn't possible? https://www.wrike.com/open.htm?id=123008490

            /*
            !renameCB && buttons.push({
                title: _("media.playlist.rename"),
                callback: renameCB
             });
             */
            !!editCB && buttons.push({
                title: _("media.playlist.edit-list"),
                callback: editCB
            });
            !!rmCB && buttons.push({
                title: _("media.playlist.delete"),
                callback: rmCB
            });
        } else if (item[MusicServerEnum.Event.FILE_TYPE] === MusicServerEnum.FileType.FOLLOWED_PLAYLIST) {
            !!rmCB && buttons.push({
                title: _("media.playlist.unfollow"),
                callback: rmCB
            });
        }

        return buttons;
    };
    /**
     * Returns a set of audioZones that are available on this Media Server. Converted into an object
     * whos attributes are converted so they can be used by the AudioserverComp source just like if
     * they came from the MediaServer and not the Miniservers structure file.
     * @returns {{}}    set of audioZones.
     */


    AudioserverComponent.prototype.getAvailableZones = function getAvailableZones(ignoreFixed) {
        var zoneControls = ActiveMSComponent.getStructureManager().getControlsByType(ControlType.AUDIO_ZONE_V2),
            availableZoneControls = [];
        zoneControls.forEach(function (zoneControl) {
            if (zoneControl.isFixedGroup && !ignoreFixed) {
                if (!availableZoneControls.find(function (zone) {
                    return zone.isFixedGroup && zone.isControlPartOfFixedGroup(zoneControl);
                })) {
                    availableZoneControls.push(zoneControl);
                }
            } else {
                availableZoneControls.push(zoneControl);
            }
        }.bind(this));
        return availableZoneControls;
    };

    AudioserverComponent.prototype.getControlByPlayerId = function getControlByPlayerId(playerId) {
        var compareId = "" + playerId,
            playerCompareId;
        return ActiveMSComponent.getStructureManager().getControlsByType(ControlType.AUDIO_ZONE_V2).find(function (zone) {
            playerCompareId = "" + zone.details.playerid; // ensure that IDs are compared as strings, not int<>string.

            return playerCompareId === compareId;
        }.bind(this));
    };
    /**
     * Returns whether or not the given audioType can be controlled. We do not have any power when airplay
     * or a line-in is active, so we mustn't show any controls indicating that we can do anything with them.
     * @param [audioType] the type which is to be evaluated
     * @returns {boolean} whether or not the type is controllable
     */


    AudioserverComponent.prototype.isControllableAudioType = function isControllableAudioType(audioType) {
        var controllable = true;

        switch (audioType) {
            case MusicServerEnum.AudioType.AIRPLAY:
            case MusicServerEnum.AudioType.BLUETOOTH:
            case MusicServerEnum.AudioType.SPOTIFY_CONNECT:
                //case MusicServerEnum.AudioType.LINEIN: --> you can still control while you're in line in.
                controllable = false;
        }

        return controllable;
    };
    /**
     * Returns whether or not the given audioType is a stream. it does not take into consideration if it's controllable
     * or not.
     * @param [audioType] the type which is to be evaluated
     * @returns {boolean} whether or not the type is a stream
     */


    AudioserverComponent.prototype.isStream = function isStream(audioType) {
        var isStream = false;

        switch (audioType) {
            case MusicServerEnum.AudioType.SPOTIFY_CONNECT:
            case MusicServerEnum.AudioType.AIRPLAY:
            case MusicServerEnum.AudioType.BLUETOOTH:
            case MusicServerEnum.AudioType.STREAM:
            case MusicServerEnum.AudioType.LINEIN:
                isStream = true;
        }

        return isStream;
    };
    /**
     * Gets the last selected user from the local storage
     * @param [forCurrentControl] if true the username for the current control will be returned
     * @returns {*}
     */


    AudioserverComponent.prototype.getCurrentSpotifyId = function getCurrentSpotifyId(forCurrentControl) {
        return PersistenceComponent.getShared(MusicServerEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID).then(function (res) {
            return res;
        }, function () {
            return {};
        }).then(function (res) {
            res = res || {};

            if (forCurrentControl) {
                return res[this.getActiveZoneControl().uuidAction];
            } else {
                return res;
            }
        }.bind(this));
    };


    /**
     * Returns whether or not the item provided is a soundsuit schedule
     * @param item
     * @returns {boolean}
     */
    AudioserverComponent.prototype.isSoundsuitSchedule = function isSoundsuitSchedule(item) {
       return !!(
           (item.hasOwnProperty("audiopath") || item.hasOwnProperty("id")) &&
           (item.audiopath || item.id) &&
           (item.audiopath || item.id).indexOf("soundsuit") !== -1 &&
           (item.audiopath || item.id).indexOf(":schedule:") !== -1
       );
    };

    /**
     * Will open up a link directing the user to the soundsuit app/website where the schedule can be edited.
     * @param item
     */
    AudioserverComponent.prototype.openEditSoundsuitSchedule = function openEditSoundsuitSchedule(item) {
        //TODO-woessto: replace with proper link!
        NavigationComp.openWebsite(MusicServerEnum.ServiceHelpLinks.SOUNDSUIT);
    };



    /**
     * Will return a properly formated name for the item passed in here.
     * @param item
     */
    AudioserverComponent.prototype.getNameForItem = function getNameForItem(item) {
        // clone item, no modifications on the input argument.
        var cleanItem = this.decodeItem(cloneObject(item)),
            name = cleanItem[MusicServerEnum.Event.NAME];

        if (!name) {
            var station = cleanItem[MusicServerEnum.Event.STATION]; // if a station attribute is given, use it and ignore the rest (it mostly will reflect current track info on the stream)

            if (station && station !== "") {
                name = station;
            } else {
                var title = cleanItem[MusicServerEnum.Event.TITLE];
                var artist = cleanItem[MusicServerEnum.Event.ARTIST];
                var album = cleanItem[MusicServerEnum.Event.ALBUM];

                if (artist) {
                    name = artist;

                    if (title) {
                        name += " - ";
                    }
                }

                if (title) {
                    if (name) {
                        name += title;
                    } else {
                        name = title;
                    }
                }

                if (album) {
                    name += " (" + album + ")";
                }
            }
        }

        return name;
    };
    /**
     * Gets the control object of the audioZone affected by the command provided.
     * @param svrUuid   the cmd target uuid (has to be the mediaServerUuid)
     * @param command   the audio/xx/xxx cmd
     * @returns {*} either a control or null.
     */


    AudioserverComponent.prototype.getZoneFromCommand = function getZoneFromCommand(svrUuid, command) {
        var zone = null;
        var mediaServer = this.getMediaServer(svrUuid);

        if (mediaServer && mediaServer.uuidAction === svrUuid) {
            var audioParts = decodeURIComponent(command).split("/");
            var playerid = parseInt(audioParts[1]);
            zone = this.getAvailableZones().find(function (zone) {
                return zone.details.playerid === playerid;
            });
        }

        return zone;
    };

    AudioserverComponent.prototype.isSearchableContentType = function isSearchableContentType(type, service) {
        var result = false;

        switch (type) {
            case MusicServerEnum.MediaContentType.SERVICE:
                result = this.extensions.serviceHandlerExt.isSearchableService(service);
                break;

            case MusicServerEnum.MediaContentType.LIBRARY:
                result = true;
                break;

            case MusicServerEnum.MediaContentType.PLAYLISTS:
                result = this.Feature.SEARCHABLE_PLAYLISTS;
                break;

            default:
                break;
        }

        return result;
    };
    /**
     * Used to create a zoneFavorite save command. Handles all types, except for creating a zone favorite form the queue
     * @param playerid              what player is being edited
     * @param item                  the item object that is being saved as favorite
     * @param name                  name of the new zone favorte
     * @param contentType           contentType, e.g. Library, Service
     * @param [mediaTypeDetails]    if the contentType is service, there have to be mediaTypeDetails
     * @returns {string}            the command used to save this new zone favorite. null if the contentype wasn't supported.
     */


    AudioserverComponent.prototype.getZoneFavoriteSaveCommand = function getZoneFavoriteSaveCommand(playerid, item, name, contentType, mediaTypeDetails) {
        var cmd = "audio/cfg/" + MusicServerEnum.AudioCommands.ZONE_FAV.BASE + playerid + "/" + MusicServerEnum.AudioCommands.ZONE_FAV.ADD;
        name = encodeURIComponent(name);
        /*
        audio/PlayerID/roomfav/saveid/SlotID/FileID/Name		        Lokale Bibliothek, files / folders -> ID aus DB
        audio/PlayerID/roomfav/savepath/SlotID/audiopath/Name		    (echter Path) Radio streamurl etc (urlencoded).
        audio/PlayerID/roomfav/saveexternalid/SlotID/service/ID/Name	service = spotify, googlemusic
        */

        switch (contentType) {
            case MusicServerEnum.MediaContentType.LIBRARY:
                cmd += name + "/";
                cmd += item[MusicServerEnum.Event.AUDIO_PATH] || item[MusicServerEnum.Event.ID];
                break;

            case MusicServerEnum.MediaContentType.PLAYLISTS:
            case MusicServerEnum.MediaContentType.FAVORITES:
            case MusicServerEnum.MediaContentType.PLAYING:
            case MusicServerEnum.MediaContentType.SEARCH:
                cmd = this._getSaveZoneFavByPathCmd(cmd, item, name);
                break;

            case MusicServerEnum.MediaContentType.SERVICE:
            case MusicServerEnum.MediaContentType.SOUNDSUIT:
                var identifier = mediaTypeDetails.service.cmd;

                if (this.isRadioService(identifier)) {
                    // deal with a radio --> they are identified by their path uniquely
                    cmd = this._getSaveZoneFavByPathCmd(cmd, item, name);
                } else {
                    // deal with a service
                    cmd += name + "/";
                    cmd += item[MusicServerEnum.Event.AUDIO_PATH] || item[MusicServerEnum.Event.ID];
                }

                break;

            case MusicServerEnum.MediaContentType.LINEIN:
                if (this.Feature.INPUT_ZONE_FAVS) {
                    cmd += name + "/";
                    cmd += item.cmd || item.audiopath;
                }

                break;
        }

        return cmd;
    };
    /**
     * Will return the command to save this item as zone favorite based on its path - if available. Null if no path is provided.
     * @param cmd
     * @param item
     * @param name
     * @return {*}
     * @private
     */


    AudioserverComponent.prototype._getSaveZoneFavByPathCmd = function _getSaveZoneFavByPathCmd(cmd, item, name) {
        var newCmd = cmd;

        if (item.hasOwnProperty(MusicServerEnum.Event.AUDIO_PATH) && item[MusicServerEnum.Event.AUDIO_PATH] !== "") {
            newCmd += name + "/";
            newCmd += encodeURIComponent(item[MusicServerEnum.Event.AUDIO_PATH]);
        } else {
            newCmd = null;
            console.error("The item has no audioPath, therefore it cannot be added! " + JSON.stringify(item));
        }

        return newCmd;
    };
    /**
     * Sends a given add zone favorite command and presents a confirmation feedback if succeeded. Returns a promise that
     * resolves or rejects depending on the add result.
     * @param cmd
     * @param item
     * @param name
     * @param slot
     * @param zone  control-object of the current zone
     * @returns {deferred.promise|{then, catch, finally}}
     */


    AudioserverComponent.prototype.sendAddZoneFavoriteCmd = function sendAddZoneFavoriteCmd(cmd, item, name, zone) {
        var promise = this.sendMediaServerCommand(cmd);
        promise.done(function () {
            Debug.Media.Component && console.log(this.name + ": Saving Audio-Zone-Fav succeeded " + cmd);
            this.showConfirmationFeedback(_("media.notifications.fav.add"));
        }.bind(this), function () {
            console.error(this.name + ": Saving Audio-Zone-Fav failed " + cmd);
        }.bind(this));
        return promise;
    };
    /**
     * Returns if an media item has content to show in a detailOverlay
     * @param contentType   the mediaContentType of the item (Search, Service, ..)
     * @param item          the item in question itself.
     * @returns {boolean}
     */


    AudioserverComponent.prototype.hasDetailOverlay = function hasDetailOverlay(contentType, item) {
        var hasDetail = true;

        switch (contentType) {
            case MusicServerEnum.MediaContentType.SERVICE:
            case MusicServerEnum.MediaContentType.SEARCH:
                hasDetail = this.isFileTypePlayable(item[MusicServerEnum.Event.FILE_TYPE], contentType);
                break;

            default:
                break;
        }

        return hasDetail;
    }; // ----------------------------------------------------------------
    // Displaying Feedback
    // ----------------------------------------------------------------

    /**
     * Used for showing a confirmation (e.g. Playlist saved) to the user.
     * Presents a notification on the bottom that contains a text. It's only shown temporarily (5 seconds), but
     * if the user taps it, it'll turn into a popup and stays until the user removes clicks it.
     * @param text  the text to show in the popup.
     */


    AudioserverComponent.prototype.showConfirmationFeedback = function showConfirmationFeedback(text) {
        if (this.notification) {
            this.notification.remove();
        }

        this.notification = GUI.Notification.createGeneralNotification({
            subtitle: text,
            closeable: false,
            clickable: true,
            removeAfter: 5
        }, NotificationType.SUCCESS);
        this.notification.on(GUI.Notification.CLICK_EVENT, function () {
            this.notification.remove();
            NavigationComp.showPopup({
                message: text,
                buttonOk: true
            });
        }.bind(this));
        this.notification.on("destroy", function () {
            this.notification = null;
        }.bind(this));
    };
    /**
     * Fallback for when the MediaServer returns items the app cannot process. It'll show a popup in english since this
     * should only occur during development. Or if the user updates the Music Server but has an outdated app/wi
     * @param item  the item that cannot be handled.
     */


    AudioserverComponent.prototype.unhandledItem = function unhandledItem(item) {
        var txt = "The item '" + item.name + "' cannot be processed.<br>(type: " + item[MusicServerEnum.Event.FILE_TYPE] + ")";
        this.showContent({
            title: "Item not handled",
            message: txt,
            buttonOk: true,
            icon: Icon.INFO
        });
    }; // ----------------------------------------------------------------
    // AudioZoneExt
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.registerForAudioZone = function registerForAudioZone() {
        // this is the first method that is called when an audioZone/audioView is present on startup, check extensions here too.
        this._checkExtensions(); // structureReady is already fired, but the AudioserverComp receives it after the UI


        return this.extensions.audioZoneExt.registerForAudioZone.apply(this.extensions.audioZoneExt, arguments);
    };

    AudioserverComponent.prototype.unregisterFromAudioZone = function unregisterFromAudioZone() {
        this.extensions.audioZoneExt.unregisterFromAudioZone.apply(this.extensions.audioZoneExt, arguments);
    };

    AudioserverComponent.prototype.sendAudioZoneCommand = function sendAudioZoneCommand() {
        return this.extensions.audioZoneExt.send.apply(this.extensions.audioZoneExt, arguments);
    };
    /**
     * Will append the parent info (= who's the parent, and what position is the selected item at). Takes care
     * of feature checking before data is appended to the command.
     * @param cmd       the command to add the parent info to
     * @param row       what is the row of the item that will be played with this command?
     * @param parent    the parent whos info is to be appended.
     * @param item      the item whos parent info should be appended
     * @returns {*}     the adopted command, including the parentInfo.
     */


    AudioserverComponent.prototype.appendParentInfo = function appendParentInfo(cmd, row, parent, item) {
        if (!this.Feature.PLAY_WHOLE_LIST) {
            return cmd;
        }

        if (this.isSameItem(parent, item)) {
            // e.g. in overview-screens a parent info may be present (e.g. for liked songs), ensure that no parent info
            // is appended in such situations, as it would mess with shuffle-play vs regular play!
            return cmd;
        }

        var newCmd = cmd;

        try {
            // first of all, determine the parents info.
            if (parent.hasOwnProperty(MusicServerEnum.Event.AUDIO_PATH) && parent[MusicServerEnum.Event.AUDIO_PATH] !== "") {
                newCmd += MusicServerEnum.ParentInfo.PATH + encodeURIComponent(parent[MusicServerEnum.Event.AUDIO_PATH]);
            } else if (parent.hasOwnProperty(MusicServerEnum.Event.ID)) {
                newCmd += MusicServerEnum.ParentInfo.ID + encodeURIComponent(parent[MusicServerEnum.Event.ID]);
            } else {
                throw new Error("Parent has neither got an ID nor an AudioPath! " + JSON.stringify(parent));
            } // now lets append the item position


            if (row >= 0) {
                newCmd += "/" + row;
            } else {
                throw new Error("The items position could not be determined!");
            }
        } catch (err) {
            console.error(this.name + ":Could not acquire parent info! " + JSON.stringify(err));
            console.error(err);
            newCmd = cmd;
        }

        return newCmd;
    };/**
     * Checks if those two items provided are equal, based on id and audiopath.
     * @param itemA
     * @param itemB
     * @returns {boolean}
     */


    AudioserverComponent.prototype.isSameItem = function isSameItem(itemA, itemB) {
        var isSame = false;

        if (itemA.hasOwnProperty(MusicServerEnum.Event.AUDIO_PATH) && itemB.hasOwnProperty(MusicServerEnum.Event.AUDIO_PATH)) {
            isSame = itemA[MusicServerEnum.Event.AUDIO_PATH] === itemB[MusicServerEnum.Event.AUDIO_PATH];
        } else if (itemA.hasOwnProperty(MusicServerEnum.Event.ID) && itemB.hasOwnProperty(MusicServerEnum.Event.ID)) {
            isSame = itemA[MusicServerEnum.Event.ID] === itemB[MusicServerEnum.Event.ID];
        }

        return isSame;
    }; // ----------------------------------------------------------------
    // Settings
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.registerForAudioZoneSettings = function registerForAudioZoneSettings() {
        return this.extensions.audioZoneSettingsExt.registerForAudioZoneSettings.apply(this.extensions.audioZoneSettingsExt, arguments);
    };

    AudioserverComponent.prototype.unregisterFromAudioZoneSettings = function unregisterFromAudioZoneSettings() {
        this.extensions.audioZoneSettingsExt.unregisterFromAudioZoneSettings.apply(this.extensions.audioZoneSettingsExt, arguments);
    };

    AudioserverComponent.prototype.sendAudioZoneSettingsCommand = function sendAudioZoneSettingsCommand() {
        this.extensions.audioZoneSettingsExt.sendSetting.apply(this.extensions.audioZoneSettingsExt, arguments);
    };


    // ----------------------------------------------------------------
    // Services & Radios
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.supportsAccountZoneAssignment = function supportsAccountZoneAssignment() {
        return !!this.Feature.FIXED_ZONE_ACCOUNTS;
    };

    AudioserverComponent.prototype.assignSpotifyAccountToZone = function assignSpotifyAccountToZone(userId, zoneId) {
        return this.extensions.serviceHandlerExt.assignSpotifyAccountToZone(...arguments);
    };

    AudioserverComponent.prototype.assignSoundsuitAccountToZone = function assignSoundsuitAccountToZone(userId, zoneId) {
        return this.extensions.serviceHandlerExt.assignSoundsuitAccountToZone(...arguments);
    };

    AudioserverComponent.prototype.getCurrentServices = function getCurrentServices() {
        return this.extensions.serviceHandlerExt.getCurrentServices.apply(this.extensions.serviceHandlerExt, arguments);
    };

    AudioserverComponent.prototype.getAvailableServices = function getAvailableServices() {
        return this.extensions.serviceHandlerExt.getAvailableServices.apply(this.extensions.serviceHandlerExt, arguments);
    };

    AudioserverComponent.prototype.getServiceName = function getServiceName() {
        return this.extensions.serviceHandlerExt.getServiceName.apply(this.extensions.serviceHandlerExt, arguments);
    };

    AudioserverComponent.prototype.getServiceIcon = function getServiceIcon() {
        return this.extensions.serviceHandlerExt.getServiceIcon.apply(this.extensions.serviceHandlerExt, arguments);
    };

    AudioserverComponent.prototype.registerForServiceChanges = function registerForServiceChanges() {
        return this.extensions.serviceHandlerExt.registerForServiceChanges.apply(this.extensions.serviceHandlerExt, arguments);
    }; // Radios


    AudioserverComponent.prototype.getRadios = function getRadios() {
        return this.extensions.serviceHandlerExt.getRadios.apply(this.extensions.serviceHandlerExt, arguments);
    };

    AudioserverComponent.prototype.registerForRadioChanges = function registerForRadioChanges(cb) {
        if (this.extensions.serviceHandlerExt) {
            return this.extensions.serviceHandlerExt.registerForRadioChanges.apply(this.extensions.serviceHandlerExt, arguments);
        }
        return () => {};
    };


    /**
     * Checks wether or not the "cmd" attribute given is one of the radios or not. Needed in the search.
     * @param cmd the cmd attribute returned by the getradios-cmd (or the getservices cmd)
     * @returns {boolean} wether or not it's a radio
     */


    AudioserverComponent.prototype.isRadioService = function isRadioService(cmd) {
        return this.extensions.serviceHandlerExt.isRadio.apply(this.extensions.serviceHandlerExt, arguments);
    };


    AudioserverComponent.prototype.addCustomStream = function addCustomStreamURL(name, url, icon = null) {
        return this.extensions.serviceHandlerExt.addCustomStream(name, url, icon);
    };

    AudioserverComponent.prototype.updateCustomStream = function updateCustomStreamURL(id, name, url, icon = null) {
        return this.extensions.serviceHandlerExt.updateCustomStream(id, name, url, icon);
    }

    AudioserverComponent.prototype.removeCustomStream = function removeCustomStream(id) {
        return this.extensions.serviceHandlerExt.removeCustomStream(id);
    }

    AudioserverComponent.prototype.setCustomStreamIcon = function setCustomStreamIcon(id, iconB64) {
        return this.extensions.serviceHandlerExt.setCustomStreamIcon(...arguments);
    }


    AudioserverComponent.prototype.isAirPlayEnabled = function isAirPlayEnabled() {
        return this.sendMediaServerCommand(MusicServerEnum.Commands.SETTINGS.AIRPLAY.STATE).then(function (data) {
            return data.status;
        });
    }, AudioserverComponent.prototype.setAirPlayEnabled = function setAirPlayEnabled(enabled) {
        return this.sendMediaServerCommand(enabled ? MusicServerEnum.Commands.SETTINGS.AIRPLAY.ENABLE : MusicServerEnum.Commands.SETTINGS.AIRPLAY.DISABLE);
    },
        /**
         * Provides info on whether or not a service does support playlists.
         * @param service           the service object to check
         * @returns {boolean}     true if this service supports playlists.
         */
        AudioserverComponent.prototype.doesServiceSupportPlaylists = function doesServiceSupportPlaylists(service) {
            // first of all, is it real music service (like spotify)
            var doesSupport = this.isMusicService(service); // the music server version also has to fit the purpose.

            doesSupport = doesSupport && this.Feature.NEW_PLAYLISTS; // the service itself has to support playlists (atm only spotify does that)

            doesSupport = doesSupport && service[MusicServerEnum.Attr.SERVICE.CMD] === MusicServerEnum.Service.SPOTIFY;
            return doesSupport;
        };
    /**
     * Provides info on whether or not a service does support playlists.
     * @param service           the service object to check
     * @returns {boolean}     true if this service supports playlists.
     */

    AudioserverComponent.prototype.isMusicService = function isMusicService(service) {
        return this.extensions.serviceHandlerExt.isMusicService.apply(this.extensions.serviceHandlerExt, arguments);
    };
    /**
     * Returns true if the item provided can be added to a playlist. e.g. a radio or an input can't be added to playlists.
     * @param contentType   the contentType in question
     * @param item          the item in question
     * @param service       the service in question
     * @returns {*}
     */


    AudioserverComponent.prototype.isItemForPlaylist = function isItemForPlaylist(contentType, item, service) {
        var playable = this.isFileTypePlayable(item[MusicServerEnum.Event.FILE_TYPE], contentType),
            isStream = !!service && this.isRadioService(service[MusicServerEnum.Attr.SERVICE.CMD]),
            isFavorite = contentType === MusicServerEnum.MediaContentType.FAVORITES || contentType === MusicServerEnum.MediaContentType.ZONE_FAVORITES,
            hasFeature = this.Feature.NEW_PLAYLISTS;

        if (!isStream && item.hasOwnProperty(MusicServerEnum.Event.AUDIO_TYPE)) {
            switch (item[MusicServerEnum.Event.AUDIO_TYPE]) {
                case MusicServerEnum.AudioType.STREAM:
                case MusicServerEnum.AudioType.AIRPLAY:
                case MusicServerEnum.AudioType.BLUETOOTH:
                case MusicServerEnum.AudioType.LINEIN:
                    isStream = true;
                    break;

                default:
                    break;
            }
        }

        return hasFeature && playable && !isStream && !isFavorite;
    };
    /**
     * Returns true if the item provided can be stored as favorite.
     * @param item          the item in question
     * @returns {*}
     */


    AudioserverComponent.prototype.isItemForFavorites = function isItemForFavorites(item) {
        var canBeFavorite = true;

        if (item.hasOwnProperty(MusicServerEnum.Event.AUDIO_TYPE) &&
            item.contentType !== MusicServerEnum.MediaContentType.SOUNDSUIT) {
            switch (item[MusicServerEnum.Event.AUDIO_TYPE]) {
                case MusicServerEnum.AudioType.STREAM:
                case MusicServerEnum.AudioType.AIRPLAY:
                case MusicServerEnum.AudioType.BLUETOOTH:
                    canBeFavorite = false;
                    break;

                default:
                    break;
            }
        }

        return canBeFavorite;
    }; // ----------------------------------------------------------------
    // Zone Favorites
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.selectNewZoneFavorite = function selectNewZoneFavorite() {
        return this.extensions.zoneFavExt.selectNewZoneFavorite.apply(this.extensions.zoneFavExt, arguments);
    };

    AudioserverComponent.prototype.deleteZoneFavorite = function deleteZoneFavorite() {
        return this.extensions.zoneFavExt.deleteZoneFavorite.apply(this.extensions.zoneFavExt, arguments);
    }; // ----------------------------------------------------------------
    // Inputs
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.getInputs = function getLineIns() {
        return this.extensions.inputExt.getInputs.apply(this.extensions.inputExt, arguments);
    };
    /**
     * Looks up an input based on the customization key provided.
     * @param customizationKey
     * @returns {null|*}
     */


    AudioserverComponent.prototype.getInputFromCustomizationKey = function getInputFromCustomizationKey(customizationKey) {
        var result = this.extensions.inputExt.getInputs.apply(this.extensions.inputExt, arguments);
        var inputs = result.data;
        var keyParts = customizationKey.split("-");

        if (!inputs || inputs.length === 0) {
            console.error(this.name, "getInputFromCustomizationKey failed - no inputs known yet!", result);
            return null;
        } else if (!Controls.AudioZoneV2Control.SingleTones.CustomizationManager.isInputCustomizationKey(customizationKey)) {
            console.error(this.name, "getInputFromCustomizationKey failed - the customization key provided isn't for inputs!" + customizationKey);
            return null;
        } else if (keyParts.length > 2) {
            // contains an audiopath
            return this.filterInputByAudiopath(inputs, keyParts[2])[0];
        } else {
            console.warn(this.name, "getInputFromCustomizationKey - no audiopath provided, return the first from the curr audioserver! key = " + customizationKey); // no audiopath contained, "the old way"

            return cloneObject(this.filterCurrentInputs(inputs)[0]);
        }
    };
    /**
     * Returns a customization-key specific for the provided input.
     * @param input
     * @returns {string}
     */


    AudioserverComponent.prototype.getCustomizationKeyForInput = function getCustomizationKeyForInput(input) {
        return MusicServerEnum.ControlContentMenuId.LINE_IN + "-" + input.audiopath;
    };
    /**
     * Checks if the keyList provided contains a generic "line-in" key, if so, it removes the generic one and
     * fills the list with the specific customization keys. This is handy as one only needs to allow "line-ins"
     * with one key, then using this method, automatically all available line-ins are allowed. (of blocked)
     * @param keyList
     */


    AudioserverComponent.prototype.fillWithInputCustomizationKeys = function fillWithInputCustomizationKeys(keyList) {
        var inputKeyIndex = -1;
        var containsInputKey = keyList.some(function (key, idx) {
            if (key === MusicServerEnum.ControlContentMenuId.LINE_IN) {
                inputKeyIndex = idx;
                return true;
            }

            return false;
        });

        if (containsInputKey) {
            // delete the generic input key
            keyList.splice(inputKeyIndex, 1); // add the specific ones

            this.getInputs().data.forEach(function (input) {
                keyList.push(this.getCustomizationKeyForInput(input));
            }.bind(this));
        }
    };
    /**
     * Extracts those inputs that are located on this audioserver. Usually it's only one - but may be 0 ore more in future.
     * @param inputs
     * @returns {*}
     */


    AudioserverComponent.prototype.filterCurrentInputs = function filterCurrentInput(inputs) {
        return inputs.filter(function (input) {
            return input.isFromCurrentAudioserver;
        }.bind(this));
    };
    /**
     * Looks up a specific input object that fits the audiopath
     * @param inputs
     * @param audiopath
     * @returns {*}
     */


    AudioserverComponent.prototype.filterInputByAudiopath = function filterInputByAudiopath(inputs, audiopath) {
        return inputs.filter(function (input) {
            return input.audiopath === audiopath;
        }.bind(this));
    };

    AudioserverComponent.prototype.updateInputName = function updateInputName() {
        return this.extensions.inputExt.updateInputName.apply(this.extensions.inputExt, arguments);
    };

    AudioserverComponent.prototype.updateInputType = function updateInputType() {
        return this.extensions.inputExt.updateInputType.apply(this.extensions.inputExt, arguments);
    };

    AudioserverComponent.prototype.updateInputEnabled = function updateInputEnabled() {
        return this.extensions.inputExt.updateInputEnabled.apply(this.extensions.inputExt, arguments);
    };

    AudioserverComponent.prototype.updateInputVolume = function updateInputVolume() {
        return this.extensions.inputExt.updateInputVolume.apply(this.extensions.inputExt, arguments);
    }; // ----------------------------------------------------------------
    // Media Loaders
    // ----------------------------------------------------------------

    /**
     * Returns the root id to request content of a specific type.
     * @param contentType           e.g. playlists, service, ..
     * @param [mediaTypeDetails]    if sth is loaded from a service, it might have mediaTypeDetails
     * @returns {string}
     */


    AudioserverComponent.prototype.getRootIdForContent = function getRootIdForContent(contentType, mediaTypeDetails) {
        //TODO-woessto: what different kinds of start-ids do we have?
        return "0"; // "start"
    };

    AudioserverComponent.prototype.requestContent = function requestContent(contentType, identifier, packageSize, mediaTypeDetails) {
        const contentRequestedTiming = timingNow();
        var result = null;

        console.log(this.name, "requestContent: " + contentType + ", id=" + identifier, mediaTypeDetails, getStackObj());
        try {
            var loader = this.getLoaderForContentType(contentType, identifier, mediaTypeDetails);
            result = loader.requestContent.apply(loader, [identifier, packageSize, mediaTypeDetails]);
        } catch (ex) {
            console.error("An error occurred while requesting content for " + identifier + " " + contentType + " - " + JSON.stringify(mediaTypeDetails));
            console.error("Exception: ", ex);
        }
        result.promise.then(() => {
            console.warn("=".repeat(50));
            console.info("Content requested: \n", [`Type: ${contentType}`, `Identifier: ${identifier}`, `Details: ${JSON.stringify(mediaTypeDetails)}`].join("\n"), "\nTiming: ", timingDelta(contentRequestedTiming) + "ms");
            console.warn("=".repeat(50));
        })
        return result;
    };

    AudioserverComponent.prototype.requestContentBatch = function requestContentBatch(contentType, identifier, packageSize, mediaTypeDetails, ignoreCache, detailedInfo) {
        var result = null;
        console.log(this.name, "requestContentBatch: " + contentType + ", id=" + identifier, mediaTypeDetails, getStackObj());
        try {
            var loader = this.getLoaderForContentType(contentType, identifier, mediaTypeDetails);
            result = loader.requestContentBatch.apply(loader, [identifier, packageSize, mediaTypeDetails, ignoreCache, detailedInfo]);
        } catch (ex) {
            console.error("An error occurred while requesting content batch for " + identifier + " " + contentType + " - " + JSON.stringify(mediaTypeDetails));
        }

        return result;
    };

    AudioserverComponent.prototype.invalidateContentCachesOf = function invalidateContentCachesOf(contentType, identifier, reason) {
        var loader = this.getLoaderForContentType(contentType, identifier);
        return loader.invalidateContentCachesOf.apply(loader, [identifier, reason]);
    };

    AudioserverComponent.prototype.serviceItemFollowedChanged = function serviceItemFollowedChanged(contentType, item, followed) {
        var loader = this.getLoaderForContentType(contentType),
            identifier = item.id || item.audiopath;

        if (loader.serviceItemFollowedChanged) {
            return loader.serviceItemFollowedChanged.apply(loader, [item, followed]);
        } else {
            console.warn(this.name, "serviceItemFollowedChanged not supported by " + loader.name + ", invalidating cache instead!");
            return loader.invalidateContentCachesOf.apply(loader, [identifier, MusicServerEnum.ReloadCause.CHANGED]);
        }
    };

    AudioserverComponent.prototype.prefetchContent = function prefetchContent(contentType, identifier, packageSize, mediaTypeDetails) {

        console.log(this.name, "prefetchContent: " + contentType + ", id=" + identifier, mediaTypeDetails, getStackObj());
        var loader = this.getLoaderForContentType(contentType, identifier, mediaTypeDetails);
        var args = [identifier, packageSize, mediaTypeDetails];
        loader.prefetchContent.apply(loader, args);
    };

    AudioserverComponent.prototype.stopRequestFor = function stopRequestFor(contentType, deferred, mediaTypeDetails) {
        var loader = this.getLoaderForContentType(contentType, null, mediaTypeDetails);
        loader && loader.stopRequestFor.apply(loader, [deferred, mediaTypeDetails]);
    };

    AudioserverComponent.prototype.registerForReloadEvent = function registerForReloadEvent(contentType, delegate) {
        console.log(this.name, "registerForReloadEvent: " + contentType, getStackObj());
        var loader = this.getLoaderForContentType(contentType, null, null);
        return loader ? loader.registerForReloadEvent.apply(loader, [delegate]) : null;
    };

    AudioserverComponent.prototype.unregisterFromReloadEvent = function unregisterFromReloadEvent(contentType, regId) {
        console.log(this.name, "unregisterFromReloadEvent: " + contentType, getStackObj());
        var loader = this.getLoaderForContentType(contentType, null, null);

        if (!loader) {
            console.warn("Could not unregister form '" + contentType + "'-loader because it's no longer around!");
        } else {
            return loader.unregisterFromReloadEvent.apply(loader, [regId]);
        }
    };

    AudioserverComponent.prototype.registerForScanEvents = function registerForScanEvents() {
        return this.extensions.libraryExt.registerScanListener.apply(this.extensions.libraryExt, arguments);
    };

    AudioserverComponent.prototype.unregisterFromScanEvents = function unregisterFromScanEvents() {
        return this.extensions.libraryExt.removeScanListener.apply(this.extensions.libraryExt, arguments);
    };

    AudioserverComponent.prototype.getCurrentContent = function getCurrentContent(contentType, identifier, mediaTypeDetails) {
        var loader = this.getLoaderForContentType(contentType, identifier, mediaTypeDetails);
        return loader.getCurrentContent.apply(loader, [identifier, mediaTypeDetails]);
    };

    AudioserverComponent.prototype.updateContent = function updateContent(contentType, identifier, mediaTypeDetails, newContent, reason) {
        var loader = this.getLoaderForContentType(contentType, identifier, mediaTypeDetails);
        return loader.updateContent.apply(loader, [identifier, mediaTypeDetails, newContent, reason]);
    };
    /**
     * Decodes a specific attribute of an item (if it exists)
     * @param item  the item whos attribute is to be decoded (if it exists)
     * @param attr  the attribute of the item to decode (if it exists)
     * @private
     */


    AudioserverComponent.prototype._decodeItemAttr = function _decodeItemAttr(item, attr) {
        if (item.hasOwnProperty(attr)) {
            try {
                item[attr] = decodeURIComponent(item[attr]);
            } catch (exc) {// if we fail while decoding the name, just pass it along as is.
            }
        }
    };
    /**
     * Will decode all attributes of an item.
     * @param item the item to decode.
     * @returns {*} returns the item that has been decoded (for inline usage)
     */


    AudioserverComponent.prototype.decodeItem = function decodeItem(item) {
        this._decodeItemAttr(item, MusicServerEnum.Event.NAME);

        this._decodeItemAttr(item, MusicServerEnum.Event.TITLE);

        this._decodeItemAttr(item, MusicServerEnum.Event.ARTIST);

        this._decodeItemAttr(item, MusicServerEnum.Event.ALBUM);

        this._decodeItemAttr(item, MusicServerEnum.Event.STATION);

        this._decodeItemAttr(item, MusicServerEnum.Event.COVER);

        return item;
    };
    /**
     * Will open up something that allows the user to upload files to the music server. Platform-dependent.
     */


    AudioserverComponent.prototype.openFileUpload = function openFileUpload() {
        var targetIp = AudioserverComp.getServerIp(),
            target = "smb://Guest:@" + targetIp + "/AUDIO",
            canOpen = false;

        switch (PlatformComponent.getPlatformInfoObj().platform) {
            case PlatformType.Mac:
            case PlatformType.DeveloperInterface:
            case PlatformType.Webinterface:
                canOpen = true;
                break;

            default:
                break;
        }

        var content = {
            icon: Icon.PLUS,
            title: _("media.library.upload.title"),
            message: _("media.library.info", {
                target: target
            }),
            buttonCancel: true,
            buttonOk: canOpen ? _("media.library.upload.ok") : true
        };
        NavigationComp.showPopup(content).done(function () {
            if (canOpen) {
                NavigationComp.openWebsite(target);
            }
        });
    };

    AudioserverComponent.prototype.getLoaderForContentType = function getLoaderForContentType(contentType, identifier, mediaTypeDetails) {
        var loader = null;

        switch (contentType) {
            case MusicServerEnum.MediaContentType.LIBRARY:
                loader = this.extensions.libraryExt;
                break;

            case MusicServerEnum.MediaContentType.ZONE_FAVORITES:
                loader = this.extensions.zoneFavoritesExt;
                break;

            case MusicServerEnum.MediaContentType.PLAYLISTS:
                loader = this.extensions.playlistsExt;
                break;

            case MusicServerEnum.MediaContentType.SERVICE:
                loader = this.servicesExt;
                break;

            case MusicServerEnum.MediaContentType.SEARCH:
                loader = this.extensions.searchDetailExt;
                break;

            case MusicServerEnum.MediaContentType.QUEUE:
                loader = this.extensions.queueLoaderExt;
                break;

            case MusicServerEnum.MediaContentType.LINEIN:
                loader = this.extensions.inputLoaderExt;
                break;

            case MusicServerEnum.MediaContentType.SOUNDSUIT:
                loader = this.extensions.soundsuitExt;
                break;

            default:
                throw "AudioserverComponent received an request for content with an unknown content type! " + contentType;
        }

        if (!loader) {
            console.warn("Could not get loader for '" + contentType + "', it's no longer around!");
        }

        return loader;
    }; // ----------------------------------------------------------------
    // MediaEditExtension
    // ----------------------------------------------------------------

    /**
     * Presents a Popup that will ask for a containres name.
     * @param title
     * @param [okButton]        default "Fertig"
     * @param [placeholder]
     * @param [currentName]
     * @returns {*}
     */


    AudioserverComponent.prototype.showNamePopup = function showNamePopup(title, okButton, placeholder, currentName) {
        var promise,
            content = {
                title: title,
                // _('media.playlist.save.name'),
                input: {
                    id: "name",
                    type: "text",
                    required: true,
                    // placeholder:  _("media.playlist.save.name"),
                    validationRegex: Regex.NAME
                },
                buttonOk: !!okButton ? okButton : _("finish"),
                buttonCancel: true
            };

        if (!!placeholder) {
            content.input.placeholder = placeholder;
        }

        if (!!currentName) {
            content.value = currentName;
        }

        promise = NavigationComp.showPopup(content, PopupType.INPUT);
        return promise.then(function (res) {
            return res.result;
        }.bind(this));
    };

    AudioserverComponent.prototype.createContainer = function createContainer(contentType, mediaTypeDetails, containerName) {
        if (!this.mediaEditExtension) {
            this.mediaEditExtension = this._getEditExtForContentType(contentType, mediaTypeDetails);
        }

        return this.mediaEditExtension.createContainer.apply(this.mediaEditExtension, [mediaTypeDetails, containerName]);
    };

    AudioserverComponent.prototype.deleteContainer = function deleteContainer(contentType, mediaTypeDetails, containerId) {
        if (!this.mediaEditExtension) {
            this.mediaEditExtension = this._getEditExtForContentType(contentType, mediaTypeDetails);
        }

        return this.mediaEditExtension.deleteContainer.apply(this.mediaEditExtension, [mediaTypeDetails, containerId]);
    };

    AudioserverComponent.prototype.startEditing = function startEditing(contentType, mediaTypeDetails, containerId, noDataNeeded) {
        Debug.Media.Editor && console.log(this.name, "startEditing: noDataNeeded? " + !!noDataNeeded + ", id=" + containerId, getStackObj());

        if (this.mediaEditExtension && this.mediaEditExtension.isActive()) {
            throw new Error("cannot startEditing while the editing is still in charge!");
        } else if (!this.mediaEditExtension) {
            this.mediaEditExtension = this._getEditExtForContentType(contentType, mediaTypeDetails);
        }

        return this.mediaEditExtension.startEditing.apply(this.mediaEditExtension, [mediaTypeDetails, containerId, noDataNeeded]);
    };

    AudioserverComponent.prototype.stopEditing = function stopEditing(containerId) {
        if (!this.mediaEditExtension) {
            // we may receive a "stopEditing" due to destroy of the View (not by toggling edit mode with the button)
            return true;
        }

        Debug.Media.Editor && console.log(this.name, "stopEditing: id=" + containerId);
        var result = this.mediaEditExtension.stopEditing.apply(this.mediaEditExtension, arguments);
        this.mediaEditExtension.destroy.apply(this.mediaEditExtension);
        delete this.mediaEditExtension;
        return result;
    };

    AudioserverComponent.prototype.isEditingActive = function isEditingActive() {
        return this.mediaEditExtension && this.mediaEditExtension.isActive();
    };

    AudioserverComponent.prototype.renameContainer = function renameContainer(contentType, mediaTypeDetails, containerId, containerName) {
        this._checkEditExtension();

        return this.mediaEditExtension.renameContainer.apply(this.mediaEditExtension, [mediaTypeDetails, containerId, containerName]);
    };

    AudioserverComponent.prototype.removeItem = function removeItem(idx) {
        this._checkEditExtension();

        return this.mediaEditExtension.removeItem.apply(this.mediaEditExtension, arguments);
    };

    AudioserverComponent.prototype.moveItem = function moveItem(oldIdx, newIdx) {
        this._checkEditExtension();

        return this.mediaEditExtension.moveItem.apply(this.mediaEditExtension, arguments);
    };

    AudioserverComponent.prototype.prepareForAdding = function prepareForAdding() {
        this._checkEditExtension();

        return this.mediaEditExtension.prepareForAdding.apply(this.mediaEditExtension, arguments);
    };

    AudioserverComponent.prototype.addItem = function addItem(item) {
        this._checkEditExtension();

        return this.mediaEditExtension.addItem.apply(this.mediaEditExtension, arguments);
    };

    AudioserverComponent.prototype.getAddedItemsCount = function getAddedItemsCount() {
        this._checkEditExtension();

        return this.mediaEditExtension.getAddedItemsCount.apply(this.mediaEditExtension, arguments);
    };

    AudioserverComponent.prototype.finishedAdding = function finishedAdding() {
        this._checkEditExtension();

        return this.mediaEditExtension.finishedAdding.apply(this.mediaEditExtension, arguments);
    };

    AudioserverComponent.prototype._getEditExtForContentType = function _getEditExtForContentType(contentType, mediaTypeDetails) {
        return this._getPlaylistEditExtForService(mediaTypeDetails[MusicServerEnum.Attr.SERVICE._][MusicServerEnum.Attr.SERVICE.CMD]);
    };
    /**
     * Chooses based on thes serviceCmd what kind of target we're trying to edit.
     * @param serviceCmd    e.g. spotify, googlemusic, local, lms
     * @returns {*}
     * @private
     */


    AudioserverComponent.prototype._getPlaylistEditExtForService = function _getPlaylistEditExtForService(serviceCmd) {
        var ext;

        switch (serviceCmd) {
            case MusicServerEnum.Service.SPOTIFY:
                ext = initExtension(Components.Audioserver.extensions.SpotifyPlaylistEditExtension, this);
                break;

            default:
                // e.g. local
                ext = initExtension(Components.Audioserver.extensions.PlaylistEditExtension, this);
                break;
        }

        return ext;
    };

    AudioserverComponent.prototype._checkEditExtension = function _checkEditExtension() {
        if (!this.mediaEditExtension || !this.mediaEditExtension.isActive()) {
            throw new Error("Cannot perform edit method while no mediaEditExtension is active!");
        }
    };

    AudioserverComponent.prototype.getActiveEditingTarget = function getActiveEditingTarget() {
        var activeEditingObject = null;

        if (this.mediaEditExtension && this.mediaEditExtension.isActive()) {
            activeEditingObject = this.mediaEditExtension.getActiveTarget.apply(this.mediaEditExtension, arguments);
        }

        return activeEditingObject;
    }; // ----------------------------------------------------------------
    // ZoneGroupExt
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.getCurrentGroups = function getGroups() {
        return this.extensions.zoneGroupExt.getCurrentGroups.apply(this.extensions.zoneGroupExt, arguments);
    };

    // ----------------------------------------------------------------
    // DynamicGroupsExt
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.supportsDynamicGroups = function supportsDynamicGroups() {
        return !!this.Feature.DYNAMIC_GROUPS;
    };

    AudioserverComponent.prototype.checkForOutdatedLoginFlowFirmware = function checkForOutdatedLoginFlowFirmware(hideFeatureButton, featureName) {
        const isOutdatedLoginFlowFirmware = !this.Feature.SPOTIFY_SOUNDSUIT_NEW_LOGIN_FLOW;

        if(isOutdatedLoginFlowFirmware) {
            const currentAvailablePermissions = ActiveMSComponent.getAvailablePermissions();
            const hasPermission = hasBit(currentAvailablePermissions, MsPermission.TRIGGER_UPDATE);
            const hasCancellButtonData = typeof hideFeatureButton !== 'undefined' && typeof featureName !== 'undefined';
            const def = Q.defer();
            NavigationComp.showPopup({
                title: _("unsupported-firmware.title"),
                message: _("audioserver.unsupported-firmware.message", {
                    audioserver: this.getServerName(),
                    currVersion: this.getServerFirmwareVersion(),
                    rqVersion: MusicServerEnum.Features.SPOTIFY_SOUNDSUIT_NEW_LOGIN_FLOW.firmware
                }),
                buttonOk: hasPermission ? _("update.now") : false,
                buttonCancel: hasCancellButtonData ? _(`audio-server.${featureName.toLowerCase()}.hide`) : hasPermission,
                buttons: !hasPermission || hasCancellButtonData ? [{
                    title: _("okay"),
                    type: 'custom_cancel',
                    color: Color.STATE_INACTIVE,
                }] : [],
            }).done((button) => {
                if(button === GUI.PopupBase.ButtonType.OK) {
                    return def.resolve(ActiveMSComponent.updateToLatestRelease());
                }
                def.resolve(false);
            }, (button) => {
                if(typeof hideFeatureButton !== 'undefined' && button === GUI.PopupBase.ButtonType.CANCEL) {
                    hideFeatureButton();
                } 
                def.reject();
            });
            return def.promise;
        }
        return Q.resolve(true);
    };

    AudioserverComponent.prototype.updateGroup = function updateGroup(groupId, idArray) {
        return this.extensions.dynamicGroupsExt.updateGroup.apply(this.extensions.dynamicGroupsExt, arguments);
    };

    AudioserverComponent.prototype.registerForGroupingChanges = function registerForGroupingChanges(updFn) {
        return this.extensions.dynamicGroupsExt.registerForGroupingChanges.apply(this.extensions.dynamicGroupsExt, arguments);
    };

    AudioserverComponent.prototype.getJoinGroupList = function getJoinGroupList(visiblePlayerId) {
        return this.extensions.dynamicGroupsExt.getJoinGroupList.apply(this.extensions.dynamicGroupsExt, arguments);
    };

    AudioserverComponent.prototype.getCreateGroupList = function getCreateGroupList(visiblePlayerId) {
        return this.extensions.dynamicGroupsExt.getCreateGroupList.apply(this.extensions.dynamicGroupsExt, arguments);
    };

    AudioserverComponent.prototype.getEditGroupList = function getEditGroupList(visiblePlayerId) {
        return this.extensions.dynamicGroupsExt.getEditGroupList.apply(this.extensions.dynamicGroupsExt, arguments);
    };

    AudioserverComponent.prototype.getGroupOfPlayer = function getGroupOfPlayer(playerId) {
        return this.extensions.dynamicGroupsExt.getGroupOfPlayer.apply(this.extensions.dynamicGroupsExt, arguments);
    };

    AudioserverComponent.prototype.isPartOfDynamicGroup = function isPartOfDynamicGroup(playerId) {
        return this.extensions.dynamicGroupsExt.isPartOfDynamicGroup.apply(this.extensions.dynamicGroupsExt, arguments);
    };

    AudioserverComponent.prototype.isDynamicGroup = function isDynamicGroup(groupId) {
        return this.extensions.dynamicGroupsExt.isDynamicGroup.apply(this.extensions.dynamicGroupsExt, arguments);
    }; // ----------------------------------------------------------------
    // VoiceRecorder
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.uploadAudioFile = function uploadAudioFile() {
        return this.extensions.voiceRecorderExt.uploadAudioFile.apply(this.extensions.voiceRecorderExt, arguments);
    };

    AudioserverComponent.prototype.playRecordedFile = function playRecordedFile() {
        return this.extensions.voiceRecorderExt.playRecordedFile.apply(this.extensions.voiceRecorderExt, arguments);
    };

    AudioserverComponent.prototype.deleteFile = function deleteFile() {
        return this.extensions.voiceRecorderExt.deleteFile.apply(this.extensions.voiceRecorderExt, arguments);
    };

    AudioserverComponent.prototype.initVoiceRecorderExtension = function initVoiceRecorderExtension() {
        // This extension doesn't need an active connection with the Music Server
        this.extensions.voiceRecorderExt = initExtension(Components.Audioserver.extensions.VoiceRecorderExt, this);
    };

    AudioserverComponent.prototype.destroyAllExtensions = function destroyAllExtensions() {
        this.extensions = {};
    }; // ----------------------------------------------------------------
    // MediaSearchExt
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.searchFor = function searchFor() {
        return this.extensions.searchExt.searchFor.apply(this.extensions.searchExt, arguments);
    };

    AudioserverComponent.prototype.saveKeyword = function saveKeyword() {
        return this.extensions.searchExt.saveKeyword.apply(this.extensions.searchExt, arguments);
    };

    AudioserverComponent.prototype.deleteKeywords = function deleteKeywords() {
        return this.extensions.searchExt.deleteKeywords.apply(this.extensions.searchExt, arguments);
    };

    AudioserverComponent.prototype.loadLastUsedKeywords = function loadLastUsedKeywords() {
        return this.extensions.searchExt.loadLastUsedKeywords.apply(this.extensions.searchExt, arguments);
    };

    AudioserverComponent.prototype.browseItem = function browseItem() {
        return this.extensions.searchExt.browseItem.apply(this.extensions.searchExt, arguments);
    }; // ----------------------------------------------------------------
    // PopupExt
    // ----------------------------------------------------------------


    AudioserverComponent.prototype.showContent = function showContent() {
        return this.extensions.popupExt.showContent.apply(this.extensions.popupExt, arguments);
    }; // ----------------------------------------------------------------
    // PersistenceComp - Stub
    // ----------------------------------------------------------------

    /**
     * @see PersistenceComp.loadFile
     */


    AudioserverComponent.prototype.loadFile = function () {
        return PersistenceComponent.loadFile.apply(PersistenceComponent, arguments);
    };
    /**
     * @see PersistenceComp.saveFile
     */


    AudioserverComponent.prototype.saveFile = function () {
        return PersistenceComponent.saveFile.apply(PersistenceComponent, arguments);
    }; // ----------------------------------------------------------------
    // Private
    // ----------------------------------------------------------------

    /**
     * List of Events that are sent on this extensionChannel.
     * @type {{ConnEstablished: string, ConnClosed: string, NotReachable: string, ServerInfoReceived: string, FeatureCheckingReady: string, ReachModeChanged: string, ServerStateReceived: string, SendMessage: string, EventReceived: string, ResultReceived: string, MessageReceived: string, ResultErrorReceived: string, ShowPopup: string, ResetMediaApp: string, AvailableUpdateReceived: string, LibraryErrorReceived: string, InitialAudioScreenLoaded: string, ZoneChanged: string, TaskRecorderStart: string, TaskRecorderEnd: string, ServiceChanged: string, Start: string, Stop: string}}
     */


    AudioserverComponent.prototype.ECEvent = {
        AuthenticationChallenge: "AuthenticationChallenge",
        DidAuthenticate: "DidAuthenticate",
        DidReceiveAuthError: "DidReceiveAuthError",
        ConnEstablished: "ConnEstablished",
        ConnClosed: "ConnClosed",
        NotReachable: "NotReachable",
        ServerInfoReceived: "ServerInfoReceived",
        // used to tell the component the info was received
        FeatureCheckingReady: "FeatureCheckingReady",
        // used by the components to tell the extensions, they might now access feature checking
        ReachModeChanged: "ReachModeChanged",
        // local or remote
        ServerStateReceived: "ServerStateReceived",
        SendMessage: "SendMessage",
        MessageSent: "MessageSent",
        EventReceived: "EventReceived",
        ResultReceived: "ResultReceived",
        MessageReceived: "MessageReceived",
        // unparsed text-message received from the socket,
        ResultErrorReceived: "ResultErrorReceived",
        // the result of a command came back with an error
        ShowPopup: "ShowPopup",
        ResetMediaApp: "ResetMediaApp",
        // when the mediaServer restarts or the Miniserver changes, invalidates all caches
        AvailableUpdateReceived: "AvailableUpdateReceived",
        // internal event sent out by the popupext (Music Server publishes updates by popup-events)
        LibraryErrorReceived: "LibraryErrorReceived",
        // when an popup event is received that indicates the library is still scanned or has discovered an invalid file.
        InitialAudioScreenLoaded: 'InitialAudioScreenLoaded',
        ZoneChanged: 'ZoneChanged',
        TaskRecorderStart: "TaskRecorderStart",
        TaskRecorderEnd: "TaskRecorderEnd",
        ServiceChanged: "ServiceChanged",
        // new, edited, removed
        Start: "Start",
        Stop: "Stop",
        VerifyConnectivity: "VerifyConnectivity" //TODO-woessto: https://www.wrike.com/open.htm?id=91947923 Temporary fix until "ConnClosed" or alike is dispatched properly on the component.

    };
    /**
     * Assigns colors to zone groups from the GROUP_COLORS array.
     * @param groups    the array of groups to assign colors to
     * @param colors    list of colors (hex-strings) that should be used for the zone groups.
     */

    AudioserverComponent.prototype.assignZoneGroupColors = function assignZoneGroupColors(groups, colors) {
        // BG-I16608 --> don't assign colors, use one color to indicate that a player is grouped.
        groups.forEach(group => {
            group.color = globalStyles.colors.stateActive;
        });
        /*
        var i,
            colorList = colors ? colors : MusicServerEnum.GROUP_COLORS;
        for (i = 0; i < groups.length; i++) {
            var groupObj = groups[i];

            if (i >= colorList.length) {
                console.warn(this.name, "Reusing color that has already been used! idx: " + i + ", colors: " + colorList.length);
            }

            groupObj.color = colorList[i % colorList.length];
        }*/
    };
    /**
     * Checks if the queue action is allowed for the current players queue.
     * @param action    a string, either move, remove or insert.
     * @returns {boolean}   if true, the action is allowed, if false - it's prohibited.
     */


    AudioserverComponent.prototype.isQueueActionAllowed = function isQueueActionAllowed(action) {
        var isRestricted = this.hasQueueRestrictions();
        Debug.Control.AudioZone.Queue && console.log(this.name, "isQueueActionAllowed: " + action + "=" + !isRestricted);
        return !isRestricted;
    };
    /**
     * True if the queue operations are restricted i.e. not allowed to move/remove/insert tracks
     * @returns {boolean}
     */


    AudioserverComponent.prototype.hasQueueRestrictions = function hasQueueRestrictions() {
        var zoneStates = this.activeZoneControl ? this.activeZoneControl.getStates() : {};
        return zoneStates[MusicServerEnum.Event.QUEUE_RESTRICTIONS] === 1; // value 1 means move/remove/insert is restricted;
    };
    /**
     * send the play command in correct format
     * @param item
     * @param idx    index of the item in array
     * @param config contains -->
     *      lastSelectedItem
     *      identifier
     *      username
     *      mediaType
     */


    AudioserverComponent.prototype.sendPlayerCommandFromType = function sendPlayerCommandFromType(item, idx, config) {
        var cmdObj = {},
            cmd, isSoundsuit = false;
        Controls.AudioZoneV2Control.SingleTones.HistoryManager.shared(this.activeZoneControl).addItem(item, config);

        if (config && config.lastSelectedItem && (config.lastSelectedItem.hasOwnProperty("id") || config.lastSelectedItem.hasOwnProperty("audiopath"))) {
            switch (config.mediaType) {
                case MusicServerEnum.MediaType.LIBRARY:
                    // check if the item is a local playlist, if so - use playUrl, lib-play won't work.
                    if (item.audiopath && (item.audiopath.startsWith("playlist:") || !item.id)) {
                        cmd = MusicServerEnum.AudioCommands.SEARCH.PLAY + item.audiopath;
                    } else if (item.id) {
                        cmd = MusicServerEnum.AudioCommands.LIBRARY.PLAY + item.id;
                    }

                    break;

                case MusicServerEnum.MediaType.FAVORITES:
                    cmd = MusicServerEnum.AudioCommands.FAVORITE.PLAY + item.id;
                    break;

                case MusicServerEnum.MediaType.PLAYLIST:
                    if (item.isSpotify || item.audiopath && item.audiopath.startsWith("spotify")) {
                        cmd = MusicServerEnum.AudioCommands.SERVICE.PLAY + config.identifier + '/' + config.username + '/' + (item.audiopath || item.id);
                    } else {
                        cmd = MusicServerEnum.AudioCommands.SEARCH.PLAY + (item.audiopath || item.id);
                    }

                    break;

                case MusicServerEnum.MediaType.SERVICE:
                    cmd = MusicServerEnum.AudioCommands.SERVICE.PLAY + config.identifier + '/' + config.username + '/';

                    if (item.contentType === MusicServerEnum.MediaContentType.SOUNDSUIT) {
                        isSoundsuit = true;
                        cmd += item[MusicServerEnum.Event.AUDIO_PATH];
                    } else if (item.isSpotify && item.id === MusicServerEnum.Spotify.TYPES.TRACKS) {
                        cmd += item[MusicServerEnum.Event.AUDIO_PATH];
                    } else {
                        cmd += item.id;
                    }

                    break;

                case MusicServerEnum.MediaType.INPUT:
                    cmd = MusicServerEnum.AudioCommands.LINEIN.PLAY + item.id;
                    break;
            }

            if (idx >= 0 && !isSoundsuit) {
                cmdObj.cmd = this.appendParentInfo(cmd, idx, config.lastSelectedItem, item);
            } else {
                cmdObj.cmd = cmd;
            }

            if (config.noShuffle && !isSoundsuit) {
                cmdObj.cmd += "/noshuffle";
            }

            return this.sendAudioZoneCommand(this.activeZoneControl.details.playerid, cmdObj);
        } else if (item.contentType === MusicServerEnum.MediaContentType.ZONE_FAVORITES) {
            var cmd = MusicServerEnum.AudioCommands.ZONE_FAV.PLAY + item.id;

            if (item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_PLAYLIST ||item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_COLLECTION || item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ALBUM ||item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_SHOW || item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ARTIST ||item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_EPISODE || item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_TRACK) {
                cmd += "/" + Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.shared(this.activeZoneControl).activeUser.id;
            }

            return this.sendAudioZoneCommand(this.activeZoneControl.details.playerid, {
                cmd: cmd
            });
        } else {
            if (item.hasOwnProperty("cmd")) {
                return this.sendAudioZoneCommand(this.activeZoneControl.details.playerid, {
                    cmd: item.cmd
                });
            } else {
                return this.playFileUrl(item, config);
            }
        }
    };
    /**
     *
     * @param item
     * @param config
     * @returns {string}
     */


    AudioserverComponent.prototype.getFolderCommandFromType = function getFolderCommandFromType(item, config) {
        var cmd;

        switch (config.mediaType) {
            case MusicServerEnum.MediaType.LIBRARY:
                cmd = 'audio/cfg/getmediafolder/' + item.id + "/0/50";
                break;

            case MusicServerEnum.MediaType.PLAYLIST:
                cmd = Commands.format(MusicServerEnum.Commands.PLAYLIST.GET_PLAYLIST_2, config.username, item.id);
                break;

            case MusicServerEnum.MediaType.SERVICE:
                cmd = Commands.format(MusicServerEnum.Commands.PLAYLIST.GET_SERVICE_FOLDER, config.identifier, config.username, item.audiopath || item.id, 0, 50);
                break;
        }

        return cmd;
    };

    AudioserverComponent.prototype.copyRoomFavsFrom = function copyRoomFavsFrom(srcCtrl, vc) {
        var def = Q.defer(),
            availableZones = Object.values(this.getAvailableZones(true)).filter(function (zone) {
                return zone.details.playerid !== srcCtrl.details.playerid;
            }.bind(this)),
            details = {
                options: availableZones.map(function (control) {
                    return {
                        title: control.getName(),
                        subtitle: control.getSubtitle(),
                        clickable: true,
                        zone: control
                    };
                }),
                title: _("favorites.title"),
                headerTitle: "",
                headerDescription: _("media.zone.fav.get-from.desc", {
                    zoneName: srcCtrl.getName()
                }),
                mode: GUI.SelectorScreenMode.CONFIRMED,
                deferred: def
            };
        vc.showState(Controls.AudioZoneV2Control.Enums.ScreenState.SELECTOR_WRAPPER, null, details);
        def.promise.then(function (result) {
            // Always send the command to the "from" audioserver!
            return result[0].option.zone.audioserverComp.sendMediaServerCommand(Commands.format(MusicServerEnum.Commands.COPY_ZONE_FAVS, result[0].option.zone.details.playerid, srcCtrl.details.playerid));
        }.bind(this));
    };

    AudioserverComponent.prototype.copyRoomFavsTo = function copyRoomFavsTo(srcCtrl, vc) {
        var def = Q.defer(),
            availableZones = Object.values(this.getAvailableZones(true)).filter(function (zone) {
                return zone.details.playerid !== srcCtrl.details.playerid;
            }),
            details = {
                options: availableZones.map(function (control) {
                    return {
                        title: control.getName(),
                        subtitle: control.getSubtitle(),
                        clickable: true,
                        zone: control
                    };
                }),
                title: _("favorites.title"),
                headerTitle: "",
                headerDescription: _("media.zone.fav.send-to.desc", {
                    dstZoneName: srcCtrl.getName()
                }),
                mode: GUI.SelectorScreenMode.CONFIRMED,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                deferred: def
            };
        vc.showState(Controls.AudioZoneV2Control.Enums.ScreenState.SELECTOR_WRAPPER, null, details);
        def.promise.then(function (results) {
            return this.sendMediaServerCommand(Commands.format(MusicServerEnum.Commands.COPY_ZONE_FAVS, srcCtrl.details.playerid, results.map(function (result) {
                return result.option.zone.details.playerid;
            }).join(",")));
        }.bind(this));
    };

    AudioserverComponent.prototype.getMediaBrowserForContentType = function getMediaBrowserForContentType(contentType) {
        var browser;

        try {
            browser = Controls.AudioZoneV2Control["MediaBrowserV2" + contentType];
        } catch (e) {
            // Just use the base...
            browser = Controls.AudioZoneV2Control.MediaBrowserV2Base;
        }

        return browser;
    };
    /**
     * Minimum required version of the miniserver firmware to work with AudioserverComp.
     * @type {string}
     */


    AudioserverComponent.prototype.requiredConfigVersion = "6.4.4.15";
    /**
     * Minimum required API Version of the MediaServer to work with this AudioserverComp.
     * @type {number[]}
     */

    AudioserverComponent.prototype.supportedServerApiVersion = [2];
    /**
     * Attaches the service property for shortCuts items if needed
     * @param item
     */

    AudioserverComponent.prototype.getCellConfigWithServiceItem = function getCellConfigWithServiceItem(item) {
        var config = item.config;

        if (!MediaServerComp.Feature.V2_FIRMWARE) {
            if (item.contentType === MediaEnum.MediaContentType.SERVICE) {
                if (item.config.hasOwnProperty('lastSelectedItem') && !item.config.hasOwnProperty(MediaEnum.Attr.SERVICE._)) {
                    config[MediaEnum.Attr.SERVICE._] = item.config.lastSelectedItem;
                }
            }
        }

        return config;
    };

    AudioserverComponent.prototype.mesurePerformanceNTimes = function mesurePerformanceNTimes(cmd, nTimes) {
        var work = function (i) {
            console.time("Performance - " + i);
            this.sendMediaServerCommand(cmd).done(function () {
                console.timeEnd("Performance - " + i);

                if (i < nTimes) {
                    work(++i);
                }
            });
        }.bind(this);

        work(0);
    };

    if (!("Audioserver" in Components)) {
        Components.Audioserver = {};
    }
    Components.Audioserver.Init = AudioserverComponent;
    window.AudioserverComp = AudioserverComponent;
    return Components;
}(window.Components || {});
